import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import '../../../../styles/part4/part4firstpage/part4firstpage.css'
import '../../../../styles/part_6/page6thirdpage/page6thirdpage.css'

import React, { useState } from 'react';

import { AUDIO_SUCCESS } from '../../../../actions/types';
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import Footer from '../../../global/Footer';
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from '../../../global/Header';
import { Helmet } from 'react-helmet';
import Sidebar from '../../../global/Sidebar';
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import tipBackground from "../../../../assets/tip/Vector.png";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Part4SecondPage = () => {
    const [isPlaying, setIsPlaying] = useState(true);
    /*******   Helper Functions  *******/
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const handleAudioStoreData = () => {
        return dispatch({
            type: AUDIO_SUCCESS,
            payload: [],
        });
    }
    const nextPage = () => {
        handleAudioStoreData();
        navigate('/part_4/why_it_really_doesnt_matter', { replace: true })
    };
    const previousPage = () => {
        handleAudioStoreData();
        navigate(`/part_4/intro`, { replace: true })
    };

      // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

    /*******   Render  *******/
    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>Preparing for My Behavioural Experiment | ASRC iBroadway</title>
                </Helmet>
                <div>
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row m-0">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body  p-0 mb-0">
                                <div id="main">
                                    <div className="title  page-title-style">
                                        Preparing for My Behavioural Experiment
                                    </div>
                                    <div className="card-body part4-first-Page">
                                        <p className='component-heading-style mb-0'>
                                            Perfectionism – avoiding the trap
                                        </p>
                                        <p>
                                            Social perfectionism fuels anxiety. Thinking you have to be perfectly fluent each time you speak will only lead to:
                                        </p>
                                        <div className="main-container my-4">
                                            <div className="part-4-container">
                                                <div className="image-container">
                                                    <img
                                                        src={`${DOMAIN_NAME}images/part4/part4secondPage/192.png`}
                                                        alt="anxiety"
                                                    />
                                                </div>
                                            </div>
                                            <div className="part-4-container">
                                                <div className="image-container">
                                                    <img
                                                        src={`${DOMAIN_NAME}images/part4/part4secondPage/193.png`}
                                                        alt="procrastination"
                                                    />
                                                </div>
                                            </div>
                                            <div className="part-4-container">
                                                <div className="image-container">
                                                    <img
                                                        src={`${DOMAIN_NAME}images/part4/part4secondPage/194.png`}
                                                        alt="overuse"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Young people often worry about making mistakes and what others will think of them, especially if they stutter. Result – it stops a person from trying new things.
                                        </p>
                                        <p className='component-heading-style mt-5 ' >
                                            Perfect speech all the time is impossible
                                        </p>
                                        <p>
                                            People who are perfectionistic about their fluency pay too much attention to their speech, insisting they SHOULD always be fluent. This only makes the anxiety worse, and can often make you stutter more. Also, worrying too much about speaking perfectly is setting yourself up for disappointment because the goal is impossible. No-one speaks perfectly all the time. Next time you’re in McDonalds listen to the pauses and errors people make when trying to order such as hmmm, emmm and ahhh’s. Interestingly these people don’t seem to care about their awkward, interrupted speech, or that they’re holding up others from ordering. They are focused on achieving the planned outcome of the social interaction i.e. getting their food.
                                        </p>
                                        <p>
                                            Often it takes more effort to avoid than face a fear.
                                        </p>
                                        <p>
                                            If sometimes you act like a social perfectionist thinking your speech has to be perfect all the time, then deliberately making mistakes may seem difficult. However, if you face the fear and do it anyway, you’ll notice how much effort you’ve been putting into avoiding these things in the past. For example, purposefully stuttering, wearing a shirt with a slight stain on it, or taking your time while ordering food, will help you see that most aspects of social encounters go largely unnoticed. Doing things you avoid, without safety behaviours, will show how ‘catastrophising’ has been ruling your life.
                                        </p>
                                        <div className="tip-container">
                                            <div className="tip-image-container">
                                                <img
                                                    className="part6_bar_image"
                                                    src={tipBackground}
                                                    alt="tip-background"
                                                    width="923px"
                                                />
                                            </div>
                                            <div className="idea">
                                                <img className="part6_bar_image" src={idea} alt="tip" />
                                            </div>
                                            <div className="tip-content">
                                                <span className="whiteText">
                                                    Social confidence increases when we let go of
                                                    <span className="yellowText"> perfectionism</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <FooterOfPrint/>
                                    </div>
                                </div>

                                <div className='row mx-0 bottom-button-container'>
                                    <div className='previous1 col-6 px-0'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty m-0"
                                            onClick={previousPage}

                                        >
                                            Previous
                                        </Button>
                                    </div>
                                    <div className='next col-6 px-0'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty m-0"
                                            onClick={nextPage}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4SecondPage;