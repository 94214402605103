import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part1/waitingForBus/waitingForBus.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AnxiousCauses = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   Page hooks  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  /*******functions************/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/thinking_exercise_1`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/anxiety_is_important_in_stuttering`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /******Variabls/constatn********/
  const title = "What Causes Us to Feel Anxious?";
  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>What Causes Us to Feel Anxious? | ASRC iBroadway</title>
      </Helmet>
      <>
        <div id="printAble"></div>
        <div id="mainContainer" className="dashboardContent">
          <div>
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div className="row m-0">
            <div className="col-md-2  position-static pl-0">
              <div className="leftside">
                <Sidebar />
              </div>
            </div>
            <div className="col-md-10 content-widthsidebar content-wrapper">
              <div className="card">
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <div className="title page-title-style m-0">{title}</div>
                    <div className="card-body">
                      <div>
                        <p>
                          Sometimes it seems that our feelings of anxiety result
                          directly from the situations we are in, but this is
                          not the case. The way we FEEL is a direct result of
                          the THOUGHTS and BELIEFS we have. To understand why,
                          read the following example:
                        </p>
                        <div className="situation-box">
                          <p class="component-sub-heading-style">Situation:</p>
                          <p className="normal-text-black mb-0">
                            Austin, Bridget, Byron and Decota are waiting for
                            the same bus to get to school. Just as the bus is
                            due to pick them up, they all see it pass without
                            stopping.
                          </p>
                        </div>
                        <div className="row example-container">
                          <div className="image-box">
                            <div className="main-container">
                              <div className="sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part1/anxiousCause/38.jpg`}
                                    alt="Everyone"
                                    width=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-box">
                            <p className="component-heading-style">Feelings </p>
                            <p>
                              <ul>
                                <li>Austin felt anxious</li>
                                <li>Bridget felt angry</li>
                                <li>Byron felt sad</li>
                                <li>Decota felt happy</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        {/* <div className="main-container my-5">
                                                    <div className=" sub-container center-align">
                                                        <div className="image-container">
                                                            <img src={`${DOMAIN_NAME}images/part3/intro/168.png`} alt="hannah" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <p>
                                                    <ul>
                                                        <li>Austin felt anxious</li>
                                                        <li>Bridget felt angry</li>
                                                        <li>Byron felt sad</li>
                                                        <li>Becota felt happy</li>
                                                    </ul>
                                                </p> */}
                      </div>

                      <div className="part1-main-container mt-5 mb-3">
                        <div className="image-with-text">
                          <div className="container">
                            <img
                              className="causes"
                              src={`${DOMAIN_NAME}images/part1/anxiousCause/39.jpg`}
                              alt="Stress Release tips"
                            />
                          </div>
                          <p className="text-center">
                            <span className="normal-text-black ">
                              Anxious Austin -{" "}
                            </span>
                            "I'm going to be late for school. What is the
                            teacher going to think as I don't have a note?"
                          </p>
                        </div>
                        <div className="image-with-text">
                          <div className="container">
                            <img
                              className="causes"
                              src={`${DOMAIN_NAME}images/part1/anxiousCause/Decota_happy.jpg`}
                              alt="Stress Release tips"
                            />
                          </div>
                          <p className="text-center">
                            <span className="normal-text-black ">
                              Happy Decota -{" "}
                            </span>{" "}
                            "Great! Now I have a good excuse for being late to
                            school and I'll get to miss boring Maths!".
                          </p>
                        </div>
                        <div className="image-with-text">
                          <div className="container">
                            <img
                              className="causes"
                              src={`${DOMAIN_NAME}images/part1/anxiousCause/Byron_sad.jpg`}
                              alt="Stress Release tips"
                            />
                          </div>
                          <p className="text-center">
                            <span className="normal-text-black ">
                              Sad Byron -{" "}
                            </span>
                            "Just my luck, nothing ever goes right for me".
                          </p>
                        </div>
                        <div className="image-with-text">
                          <div className="container">
                            <img
                              className="causes"
                              src={`${DOMAIN_NAME}images/part1/anxiousCause/Bridget_angry.jpg`}
                              alt="Stress Release tips"
                            />
                          </div>
                          <p className="text-center">
                            <span className="normal-text-black ">
                              Angry Bridget -{" "}
                            </span>{" "}
                            "The driver's a tool - he should know to stop here.
                            I'm going to be late and it's not my fault".
                          </p>
                        </div>
                      </div>
                      <div className="tip-container waiting-for-the-bus">
                        <div className="tip-image-container">
                          <img
                            className="part6_bar_image"
                            // style={{ maxWidth: '1050px' }}
                            src={tipBackground}
                            alt="tip-background"
                            // width="923px"
                            // max-width='1050px'
                          />
                        </div>
                        <div className="idea">
                          <img
                            className="part6_bar_image"
                            src={idea}
                            alt="tip"
                          />
                        </div>
                        <div className="tip-content">
                          <span className="whiteText">
                            "People who always get angry often have an
                            irrational belief that the world SHOULD follow their
                            wishes. In the following pages look out for
                            <span className="yellowText"> ‘Should’ </span>
                            statements used by angry people. More on that
                            later."
                          </span>
                        </div>
                        <div>
                          <FooterOfPrint />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous col-6  px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AnxiousCauses;
