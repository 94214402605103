import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part3Reached = () => {
  const moduleName = localStorage.setItem("moduleName", "Part 3");
  const part1Accessed = localStorage.setItem("part3Accessed", "true");
  /********************************States************** */
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch()

  /*******Functions*********/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }
  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/avoidance_and_safety_behaviours", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_2/complete`, { replace: true });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Congratulations! Module 3: My Model | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            {/* <div className="row header-titlewith-text m-0">
              <div className="col-md-6 p-0 main-title-style">
                Module 3 - My Model
              </div>
              <div className="col-md-6 text-right p-0">
                <span> Welcome</span> / Module 3 - My Model
              </div>
            </div> */}
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-3">
                    {" "}
                    Module 3: My Model
                  </div>
                  <div className="card-body">
                    <div className="main-container mb-4">
                      <div className=" sub-container center-align" style={{ maxWidth: "350px" }}>
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/intro/168.jpg`}
                            alt="model"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="component-sub-heading-style ">Avoidance</p>
                    <p>
                      Unhelpful causal thoughts not only cause you to feel bad,
                      but also change the way you act by encouraging you to
                      avoid certain social situations.
                    </p>
                    <p className="component-sub-heading-style ">
                      What is avoidance?
                    </p>
                    <p>
                      Anxiety can trick you into thinking a social situation is
                      too risky to face because others will negatively judge
                      you. End result, anxiety makes you avoid the situation.
                      Have a look at the example below:
                    </p>
                    <p className="component-heading-style mt-5">
                      Have a look at the example below:
                    </p>
                    <p>
                      Hannah was about to cross the street and get a drink from
                      the shop. She felt the guy working there was cute, but
                      suddenly had the causal thought, “If I stutter he’ll think
                      I’m a loser.”
                    </p>

                    <div className="row example-container">
                      <div className="image-box">
                        <div className="main-container">
                          <div className="sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${DOMAIN_NAME}images/part3/intro/135.png`}
                                alt="hannah crossing street"
                                width=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-box">
                        <p className="component-heading-style">
                          Hannah avoided going in the shop and felt like a loser
                          for the rest of the day because of it.{" "}
                        </p>
                        <p>
                          On the next page we’ll show you why avoidance lowers
                          self confidence.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part3Reached;
