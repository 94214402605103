import React, { useState } from "react";

import { DOMAIN_NAME } from "../../env/Baseurl";

const UserNameInfo = ({ handleYourName, emptyCheck, values }) => {
  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row align-items-center login-col-wrap justify-content-center m-0">
        {/* <div className="col-md-6">
                    <div className="text-center">
                        <img 
                            height="auto" 
                            width="450px" 
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div> */}
        <div className="col-md-12">
          <div>
            <h3 className="text-center pagehead-texttitle">Your Name</h3>
            <form>
              <div className="card-body">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="input-label-style"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    defaultValue={values.first_name}
                    placeholder="Enter your First Name"
                    onChange={(e) => handleYourName("first_name", e)}
                    // onChange={(e) => handleInput(e, "first_name")}
                  />
                  {emptyCheck.first_name !== "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.first_name}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    defaultValue={values.last_name}
                    placeholder="Enter your Last Name"
                    onChange={(e) => handleYourName("last_name", e)}
                    // onChange={(e) => handleInput(e, "last_name")}
                  />
                  {emptyCheck.last_name !== "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.last_name}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNameInfo;
