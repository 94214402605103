import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7thirdpage/part7thirdpage.css";
import "../../../../styles/part_6/part6secondpage/part6secondpage.css";
import "../../../../styles/part_6/page6thirdpage/page6thirdpage.css";
import "../../../../styles/part5/part5completepage/part5completepage.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetEssay } from "../../../../services/part5/get-essay";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const WelcomeToPart5 = () => {
  const moduleName = localStorage.setItem("moduleName", "Part 5");
  const part1Accessed = localStorage.setItem("part5Accessed", "true");
  /*******   States *******/
  const [essay, errorMessage] = GetEssay();
  const [state, setState] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [postErrorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);
  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

  /******* hooks**********/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  /*******   Helper Functions  *******/
  const handleChange = () => (e) => {
    const { value } = e.target;
    setState(value);
    if (value !== "" || value === "") {
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            errorMessage: "",
            disabledMessage: "Please fill essay to continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        essay_text: state,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `essay_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state !== "") {
        navigate("/part_5/should_statements", { replace: true });
      } else {
        alert("Please fill the essay field.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        essay_text: state,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `essay_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/should_statements", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/complete`, { replace: true });
  };
  // close modal on its own
  setTimeout(handleClose, 1000);

  /*******   UseEffect *******/
  useEffect(() => {
    if (state !== "" && specialCharactersRegex.test(state) === false) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    if (essay.global_disable_status === true) {
      setDisabled(false);
    }
  }, [essay]);

  useEffect(() => {
    ModuleCompletionEmails("sendmailPart4"); // Sending module completion email
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> My Views | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div className="title page-title-style  mb-0">
                  Module 5: My Views
                </div>
                <div id="main">
                  <div className="card-body">
                    <p>
                      “Why it’s important to let go of worrying what other
                      people think of me.”
                    </p>
                    <div className="main-container my-5">
                      <div className="sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/211.jpg`}
                            alt="Ben"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      Remember Ben from the earlier example of going to the
                      under 18’s dance party? Here is Ben’s response to help
                      give you some ideas of how to do this task:
                    </p>
                    <p className="component-sub-heading-style">
                      My key points:
                    </p>
                    <div className="main-container my-3">
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/212.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/213.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/214.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/215.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/216.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/217.png`}
                            alt="key Points"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <p className='redText'>
                                                    Ben's Response *
                                                </p>
                                                <div className="textbox">
                                                    <textarea
                                                        className="form-control"
                                                        id="ControlTextarea"
                                                        rows="6"
                                                        value={benResponse}
                                                        name="ben-response"
                                                        disabled={true}
                                                    />
                                                </div> */}
                    <div className="textbox">
                      <p className="mt-4">
                        Now you have a go yourself. Try answering the following
                        questions to help write a short blog on: Please write
                        your essay in the box. Topic: Why it doesn't matter what
                        people think about me.
                      </p>

                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : essay !== undefined && essay.length !== 0 ? (
                        <>
                          {/* <p className='redText'>
                                                                Essay*
                                                            </p> */}
                          <textarea
                            readOnly={essay.obj.disable_status}
                            className="form-control"
                            id="ControlTextarea"
                            rows="6"
                            name="essay"
                            defaultValue={essay.obj.eassy}
                            onChange={handleChange()}
                          />
                          <small className="text-danger">
                            {textBoxValidation.errorMessage}
                          </small>
                        </>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                    <p className="mt-4">
                      “Why it’s important to let go of what other people think
                      of me?”
                    </p>
                    {/* <div className='img-how-to-sty centre-align'>
                                            <img src={`${DOMAIN_NAME}images/part5/part5welcomePage/219.png`} alt="welcome-to-part-5" width='500px' />
                                        </div> */}
                    <div className="main-container my-5">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part5/part5welcomePage/219.jpg`}
                            alt="questions"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>

                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    {essay !== undefined &&
                    essay.length !== 0 &&
                    essay.global_disable_status === false ? (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0 mr-2"
                          onClick={saveDraft}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {essay !== undefined &&
                    essay.length !== 0 &&
                    essay.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? textBoxValidation.disabledMessage
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body>{successMessage}</Modal.Body>
            ) : (
              <Modal.Body>{postErrorMessage}</Modal.Body>
            )}
          </Modal>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{postErrorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeToPart5;
