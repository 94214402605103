import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part3/safetyBehaviour/safetyBehaviour.css";
import "../../../../styles/accordian/accordian.css";
import "../../../../styles/part3/exampleBehaviour/exampleBehaviour.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useNavigate } from "react-router-dom";

const ExampleBehaviours = () => {
  /*******   Helper Functions  *******/
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  const title1 = "Safety Behaviours";
  const title2 = "How Safety Behaviours Cause Harm?";
  const title3 = "Safety Behaviours hide Negative Predictions";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/lets_recap", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/avoidance_and_safety_behaviours`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> Safety Behaviours | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card safety-behaviours">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title1}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p className="component-sub-heading-style ">
                              What are Safety behaviours?
                            </p>
                            <p className="">
                              Similar to a safety blanket comforting a baby,
                              safety behaviours are things that a person does to
                              decrease the risk that something bad/embarrassing
                              will happen to them while facing a feared
                              situation. They can be really subtle. Examples of
                              safety behaviours people use in social situations
                              include:
                            </p>
                            <div className="safety-main-container">
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/144.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  1. Remaining quiet or giving short answers
                                  like yes or no, to hide your stutter.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/145.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  2. Avoiding eye contact when talking to others
                                  (to avoid being dragged into more
                                  conversation).
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/146.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  3. Holding your hands over your face when
                                  talking.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/147.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  4. Keeping your hands in your pockets or
                                  stiffly by your sides.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/148.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  5. Texting on your mobile to avoid talking.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/149.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  6. Rehearsing sentences mentally before saying
                                  them.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/150.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  7. Trying to avoid difficult words or
                                  syllables when anxious.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/151.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  8. Keeping your answers short.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/152.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  9. Point to avoid having to say things out
                                  load.
                                </p>
                              </div>
                              <div className="container">
                                <img
                                  src={`${DOMAIN_NAME}images/part3/safetyBehaviour/153.jpg`}
                                  alt="sagetyBehaviour"
                                ></img>
                                <p className="listing">
                                  10. Only choose safe or easy people to talk
                                  to.
                                </p>
                              </div>
                            </div>
                            <div className="tip-container">
                              <div className="tip-image-container">
                                <img
                                  className="part6_bar_image"
                                  src={tipBackground}
                                  alt="tip-background"
                                // style={{ maxWidth: "1080px" }}
                                />
                              </div>
                              <div className="idea">
                                <img
                                  className="part6_bar_image"
                                  src={idea}
                                  alt="tip"
                                />
                              </div>
                              <div className="tip-content">
                                <span className="whiteText">
                                  Safety behaviours prevent you from learning
                                  that you can
                                  <span className="yellowText">
                                    {" "}
                                    handle anxiety{" "}
                                  </span>
                                  in certain situations.
                                </span>
                              </div>
                            </div>
                            <p>
                              Let’s see how causal thoughts bring on safety
                              behaviours, making anxiety worse.
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 1 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 1) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 1 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title2}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0 cause-harm">
                            <div className="row example-container mt-0">
                              <div className="image-box">
                                <div className="main-container">
                                  <div className="sub-container center-align">
                                    <div className="image-container">
                                      <img
                                        src={`${DOMAIN_NAME}images/part3/safetyBehaviour/155.jpg`}
                                        alt="catastrophic prediction"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-box">
                                <p className="component-heading-style">
                                  {" "}
                                  Susan is at a party with her sporting team.
                                  She is worried that people will hear her
                                  stutter so she tries not to talk.{" "}
                                </p>
                              </div>
                            </div>
                            <div className="main-container cause-harm-container">
                              <div className="container">
                                <p className="component-heading-style text-center">
                                  HARM 1: Increased self-focus
                                </p>
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part3/safetyBehaviour/156.jpg`}
                                    alt="sagetyBehaviour"
                                  />
                                  <p className="listing mt-3">
                                    Susan focuses more on herself than others
                                    for fear she’ll stutter. This makes Susan
                                    miss parts of conversations, which only
                                    increases her anxiety.
                                  </p>
                                </div>
                              </div>
                              <div className="container">
                                <p className="component-heading-style text-center">
                                  HARM 2: Prevent challenging causal thoughts
                                </p>
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part3/safetyBehaviour/157.jpg`}
                                    alt="sagetyBehaviour"
                                  />
                                  <p className="listing mt-3">
                                    Susan can’t test her negative causal
                                    thoughts, because she could only do this by
                                    dropping her safety behaviours. Remember,
                                    safety behaviours prevent her from ever
                                    discovering that her causal thoughts are
                                    simply untrue.
                                  </p>
                                </div>
                              </div>
                              <div className="container">
                                <p className="component-heading-style text-center">
                                  HARM 3: Drawing attention to oneself
                                </p>
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part3/safetyBehaviour/158.jpg`}
                                    alt="sagetyBehaviour"
                                  />
                                  <p className="listing mt-3">
                                    Susan’s safety behaviours end up backfiring.
                                    She actually draws attention to herself by
                                    avoiding eye contact and not speaking.
                                    People at the party notice that Susan avoids
                                    talking to them, and at first they wonder
                                    what’s wrong.
                                  </p>
                                </div>
                              </div>
                              <div className="container">
                                <p className="component-heading-style text-center">
                                  HARM 4: Come across as stuck up
                                </p>
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part3/safetyBehaviour/159.jpg`}
                                    alt="sagetyBehaviour"
                                  />
                                  <p className="listing mt-3">
                                    Safety behaviours make Susan look snobby.
                                    When she’s: • staring at people and not
                                    saying anything (she’s rehearsing what to
                                    say next) • avoiding eye contact • only
                                    giving short answers • playing with her
                                    phone to avoid talking
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p>
                              People at the party notice and negatively judge
                              Susan for being stuck up – the thing she feared
                              all along.
                            </p>
                            <p>
                              However, Susan still doesn’t know what’s fueling
                              her anxiety. Find out on the next page...
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 2 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 2) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 2 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title3}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p className="component-sub-heading-style">
                              Susan's Casual Thought
                            </p>
                            <div className="row sushan-thought-container my-4 mx-0">
                              <div
                                className="example-container"
                                style={{
                                  boxShadow: "none",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              >
                                <div className="image-box">
                                  <div className="main-container">
                                    <div className="sub-container center-align">
                                      <div className="image-container">
                                        <img
                                          src={`${DOMAIN_NAME}images/part3/safetyBehaviour/155.jpg`}
                                          alt="catastrophic prediction"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-box sushan-caual-thought">
                                  <p className="component-heading-style">
                                    {" "}
                                    People will doubt my abilities if I stutter.{" "}
                                  </p>
                                  <div>
                                    <div className="sushan-problem">
                                      <p className="component-sub-heading-style">
                                        The Problem
                                      </p>
                                      <p className="mb-4">
                                        Apart from her causal thought, Susan
                                        doesn’t really understand why she gets
                                        anxious all the time in social
                                        situations. She just doesn’t feel like
                                        she can drop her guard and be herself
                                        because of anxiety.
                                      </p>
                                    </div>
                                    <div className="reason-box">
                                      <p className="component-sub-heading-style">
                                        The Reason the Problem stays
                                      </p>
                                      <p className="mb-4">
                                        Susan believes her safety behaviours are
                                        protecting her and preventing her feared
                                        outcome from happening. What’s so bad
                                        about Susan’s feared outcome? Nobody
                                        knows because Susan has never questioned
                                        her causal thoughts, her catastrophic
                                        prediction of what will happen if she
                                        stutters in public has remained hidden.
                                      </p>
                                    </div>
                                    <div className="answer-box">
                                      <p className="component-sub-heading-style">
                                        Answer
                                      </p>
                                      <p>
                                        Bring this catastrophizing prediction
                                        out of hiding using the “What would be
                                        so bad about that” technique.
                                      </p>
                                      <p>
                                        Let’s see it in action on the next page.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="sushan-problem-outer">
                                  <p className="component-sub-heading-style">
                                    The Problem
                                  </p>
                                  <p className="mb-4">
                                    Apart from her causal thought, Susan doesn’t
                                    really understand why she gets anxious all
                                    the time in social situations. She just
                                    doesn’t feel like she can drop her guard and
                                    be herself because of anxiety.
                                  </p>
                                </div>
                                <div className="reason-box-outer">
                                  <p className="component-sub-heading-style">
                                    The Reason the Problem stays
                                  </p>
                                  <p className="mb-4">
                                    Susan believes her safety behaviours are
                                    protecting her and preventing her feared
                                    outcome from happening. What’s so bad about
                                    Susan’s feared outcome? Nobody knows because
                                    Susan has never questioned her causal
                                    thoughts, her catastrophic prediction of
                                    what will happen if she stutters in public
                                    has remained hidden.
                                  </p>
                                </div>
                                <div className="answer-box-outer">
                                  <p className="component-sub-heading-style">
                                    Answer
                                  </p>
                                  <p>
                                    Bring this catastrophizing prediction out of
                                    hiding using the “What would be so bad about
                                    that” technique.
                                  </p>
                                  <p>
                                    Let’s see it in action on the next page.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <p className="component-sub-heading-style">The Problem</p>
                            <p>
                              Apart from her causal thought, Susan doesn’t really
                              understand why she gets anxious all the time in social
                              situations. She just doesn’t feel like she can drop her
                              guard and be herself because of anxiety.
                            </p>
                            <p className="component-sub-heading-style">
                              The Reason the Problem stays
                            </p>
                            <p>
                              Susan believes her safety behaviours are protecting her
                              and preventing her feared outcome from happening. What’s
                              so bad about Susan’s feared outcome? Nobody knows because
                              Susan has never questioned her causal thoughts, her
                              catastrophic prediction of what will happen if she
                              stutters in public has remained hidden.
                            </p>
                            <p className="component-sub-heading-style">Answer</p>
                            <p>
                              Bring this catastrophizing prediction out of hiding using
                              the “What would be so bad about that” technique.
                            </p>
                            <p>Let’s see it in action on the next page.</p> */}
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    disabled={buttonState}
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExampleBehaviours;
