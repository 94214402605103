import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetPredictionSituation } from "../../../../services/part4/predictions-situation-task";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4SeventhPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [predictionSituation, errorMessage] = GetPredictionSituation(); // Get Prediction Situation info from api
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  /*******   States  *******/

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/experiment_completed", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/prediction_probabilities`, { replace: true });
  };
  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Exposure Time! | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div id="main">
                  <div className="title page-title-style mb-0">
                    The Exposure Time!
                  </div>
                  <div className="card-body">
                    <div className="main-container my-3">
                      <div
                        className=" sub-container center-align"
                        style={{ maxWidth: "350px" }}
                      >
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part4/part4seventhPage/203.jpg`}
                            alt="Exposure time"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="">
                      Now it’s time to go out there and do the experiment that
                      we’ve set up on the previous page. It will take courage,
                      but you can do it!
                    </p>
                    <p className="">
                      Ok,{" "}
                      <span className="normal-text-black">
                        {user.userInfo.Fname + " " + user.userInfo.Lname}
                      </span>{" "}
                      please log-out and GO FOR IT!
                    </p>
                    <p className="normal-text-black">
                      <span className="normal-text">
                        Here is your situation:{" "}
                      </span>
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>
                            {predictionSituation?.situationtask_data?.situation}
                          </>
                        )}
                      </span>
                    </p>
                    <p className="normal-text-black">
                      <span className="normal-text">Here is your task: </span>{" "}
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>{predictionSituation?.situationtask_data?.task}</>
                        )}
                      </span>
                    </p>
                    <p className="">Avoid your safety behaviours:</p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : predictionSituation !== undefined &&
                        predictionSituation.length !== 0 ? (
                        <div>
                          {predictionSituation?.safty_behaviour_data?.map(
                            (item, index) => {
                              const card = (
                                <li className="listing" key={index}>
                                  {item.question}
                                </li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                  </div>
                  <div>
                                        <FooterOfPrint/>
                                    </div>
                </div>

                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4SeventhPage;
