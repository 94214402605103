import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MoreSafetyBehaviours = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }
  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/bens_model", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/causal_thoughts_and_safety_behaviours`, {
      replace: true,
    });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            Casual Thought and Safety Behaviours in Action| ASRC iBroadway
          </title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style">
                    Casual Thought and Safety Behaviours in Action
                  </div>
                  <div className="card-body">
                    <div className="main-container my-4">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/moreSafety/172.png`}
                            alt="Lets Recap"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="">
                      In the next page we’re going to bring all these together
                      in a cool model that’s uniquely yours to show how they
                      impact each other to make anxiety worse in social
                      situations.
                    </p>
                  </div>
                </div>
                <div>
                                        <FooterOfPrint />
                                    </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreSafetyBehaviours;
