import React, { useEffect, useState } from "react";

import { DOMAIN_NAME } from "../../env/Baseurl";
import anthony from "../../Audio/Anthony bio.mp3";
import margot from "../../Audio/margot bio.mp3";
import normalPause from "../../assets/GIf/normalPause.svg";
import normalPlay from "../../assets/GIf/normalPlay.svg";
import ross from "../../Audio/roass_updated.mp3";
import selectedPause from "../../assets/GIf/selected_pause.svg";
import selectedPlay from "../../assets/GIf/selected_play.svg";

// import rossImage from "../../public/Images/registration/ross.png";
// import margotImage from "../../public/Images/registration/margot.png";
// import tonyImage from "../../public/Images/registration/Anthony.png";

const UserClinicianInfo = ({ selectionClinician, emptyCheck, values }) => {
  const [rossAudioPlayButton, setRossAudioPlayButton] = useState(
    `${normalPlay}`
  );
  const [rossAudioPauseButton, setRossAudioPauseButton] = useState(
    `${normalPause}`
  );
  const [margotPlayButton, setMargotPlayButton] = useState(`${normalPlay}`);
  const [margotPauseButon, setMargotPauseButton] = useState(`${normalPause}`);
  const [tonyPlayButton, setToneyPlayButton] = useState(`${normalPlay}`);
  const [tonyPauseButton, setTonyPauseButton] = useState(`${normalPause}`);
  const [audioPreview, setAudioPreview] = useState([
    { name: "ross", preview: false },
    { name: "margot", preview: false },
    { name: "tony", preview: false },
  ]);
  const [clinician, setClinician] = useState([
    { name: "ross", selected: false },
    { name: "margot", selected: false },
    { name: "tony", selected: false },
  ]);

  const handleClinician = (e) => {
    selectionClinician(e);
    playAudio(e);
    if (e == "ross") {
      setClinician([
        { name: "ross", selected: true },
        { name: "margot", selected: false },
        { name: "tony", selected: false },
      ]);
      ontargetMouse("ross");
      outtargetMouse("margot");
      outtargetMouse("tony");
    } else if (e == "margot") {
      setClinician([
        { name: "ross", selected: false },
        { name: "margot", selected: true },
        { name: "tony", selected: false },
      ]);
      ontargetMouse("margot");
      outtargetMouse("ross");
      outtargetMouse("tony");
    } else if (e == "tony") {
      setClinician([
        { name: "ross", selected: false },
        { name: "margot", selected: false },
        { name: "tony", selected: true },
      ]);
      ontargetMouse("tony");
      outtargetMouse("ross");
      outtargetMouse("margot");
    }
  };

  //Function that execute after the audio ended******************************

  const handleAudioEnd = (name) => {
    setAudioPreview([
      { name: "ross", preview: false },
      { name: "margot", preview: false },
      { name: "tony", preview: false },
    ]);
  };

  //Function to play audio***************************************************

  const playAudio = (e) => {
    // handleClinician(e);
    var audio1 = document.getElementById("myAudio1");
    var audio2 = document.getElementById("myAudio2");
    var audio3 = document.getElementById("myAudio3");
    if (e == "ross") {
      if (audioPreview[0].preview == false) {
        audio1.play();
        audio2.pause();
        audio3.pause();
      } else {
        audio1.pause();
      }
      setAudioPreview([
        { name: "ross", preview: !audioPreview[0].preview },
        { name: "margot", preview: false },
        { name: "tony", preview: false },
      ]);
    }
    if (e == "margot") {
      if (audioPreview[1].preview === false) {
        audio2.play();
        audio1.pause();
        audio3.pause();
      } else {
        audio2.pause();
      }
      setAudioPreview([
        { name: "ross", preview: false },
        { name: "margot", preview: !audioPreview[1].preview },
        { name: "tony", preview: false },
      ]);
    }
    if (e == "tony") {
      if (audioPreview[2].preview == false) {
        audio3.play();
        audio1.pause();
        audio2.pause();
      } else {
        audio3.pause();
      }
      setAudioPreview([
        { name: "ross", preview: false },
        { name: "margot", preview: false },
        { name: "tony", preview: !audioPreview[2].preview },
      ]);
    }
  };

  const ontargetMouse = (e) => {
    if (e == "ross") {
      document.getElementById("main1").style.color = "#ffffff";
      document.getElementsByClassName("ross-footer")[0].style.backgroundColor =
        "#3F0866";
      setRossAudioPlayButton(`${selectedPlay}`);
      setRossAudioPauseButton(`${selectedPause}`);
    } else if (e === "margot") {
      document.getElementById("main2").style.color = "#ffffff";
      document.getElementsByClassName("ross-footer")[1].style.backgroundColor =
        "#3F0866";
      setMargotPlayButton(`${selectedPlay}`);
      setMargotPauseButton(`${selectedPause}`);
    } else if (e === "tony") {
      document.getElementById("main3").style.color = "#ffffff";
      document.getElementsByClassName("ross-footer")[2].style.backgroundColor =
        "#3F0866";
      setToneyPlayButton(`${selectedPlay}`);
      setTonyPauseButton(`${selectedPause}`);
    }
  };
  const outtargetMouse = (e) => {
    if (e == "ross") {
      document.getElementById("main1").style.color = "#6A3851";
      document.getElementsByClassName("ross-footer")[0].style.backgroundColor =
        "#ffffff";
      setRossAudioPlayButton(`${normalPlay}`);
      setRossAudioPauseButton(`${normalPause}`);
    } else if (e === "margot") {
      document.getElementById("main2").style.color = "#6A3851";
      document.getElementsByClassName("ross-footer")[1].style.backgroundColor =
        "#ffffff";
      setMargotPlayButton(`${normalPlay}`);
      setMargotPauseButton(`${normalPause}`);
    } else if (e === "tony") {
      document.getElementById("main3").style.color = "#6A3851";
      document.getElementsByClassName("ross-footer")[2].style.backgroundColor =
        "#ffffff";
      setToneyPlayButton(`${normalPlay}`);
      setTonyPauseButton(`${normalPause}`);
    }
  };

  useEffect(() => {
    ontargetMouse(values.audio_preference);
  });
  return (
    <div className="row justify-content-center" style={{ marginTop: "50px" }}>
      <div className="col-8 col-md-8 col-lg-8">
        <h3 className="text-center pagehead-texttitle">
          meet & select your clinician
        </h3>
        <p className="pagehead-textsubtitle input-label-style text-center">
          On this page each of the Psychologists will introduce themselves to
          you. Once you have listened to them all, select one who will then be
          your personal clinician throughout the program
        </p>
      </div>
      <div className="col-12 card-container py-3">
        <div className="row maincard-container">
          <div className="main-audio-container">
            <div
              className={
                clinician[0].selected || values.audio_preference == "ross"
                  ? "audio-cardContainer justify-content-between clinician-selected"
                  : "audio-cardContainer justify-content-between"
              }
              onClick={() => handleClinician("ross")}
            >
              <div className="audio-imageContainer text-center">
                <img
                  className="imageStyle"
                  width="350px"
                  height="444px"
                  // src={`${DOMAIN_NAME}images/login/login8464.png`}
                  src={`${DOMAIN_NAME}images/clinicianImages/ross.jpg`}
                  alt="Ross"
                />
              </div>
              <div className="d-flex justify-content-between audioContainer-footer  align-items-center ross-footer">
                <p
                  className="clinicain-name"
                  id="main1"
                  onMouseEnter={() =>
                    clinician[0].selected || values.audio_preference == "ross"
                      ? null
                      : ontargetMouse("ross")
                  }
                  onMouseOut={() =>
                    clinician[0].selected || values.audio_preference == "ross"
                      ? null
                      : outtargetMouse("ross")
                  }
                >
                  ROSS
                </p>
              </div>
            </div>
            <div className="mediaButton-container">
              <div onClick={() => handleClinician("ross")}>
                {audioPreview[0].preview == true &&
                clinician[0].selected == true ? (
                  <img
                    className="selectedPause"
                    src={`${selectedPause}`}
                    alt="casual-park"
                  />
                ) : audioPreview[0].preview == false &&
                  clinician[0].selected == true ? (
                  <img
                    className="selectedPlay"
                    src={`${selectedPlay}`}
                    alt="casual-park"
                  />
                ) : audioPreview[0].preview == true &&
                  clinician[0].selected == false ? (
                  <img
                    className="normalPuase"
                    src={`${rossAudioPauseButton}`}
                    alt="casual-park"
                  />
                ) : (
                  <img
                    className="normalPlay"
                    src={`${rossAudioPlayButton}`}
                    alt="casual-park"
                  />
                )}
              </div>
              <audio
                id="myAudio1"
                controls=""
                onEnded={() => handleAudioEnd("ross")}
              >
                <source src={`${ross}`} type="audio/mp3"></source>
              </audio>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            className="main-audio-container"
          >
            <div
              className={
                clinician[1].selected || values.audio_preference == "margot"
                  ? "audio-cardContainer justify-content-between clinician-selected"
                  : "audio-cardContainer justify-content-between"
              }
              onClick={() => handleClinician("margot")}
            >
              <div className="audio-imageContainer text-center">
                <img
                  className="imageStyle"
                  width="350px"
                  height="444px"
                  // src={`${DOMAIN_NAME}images/login/login8464.png`}
                  src={`${DOMAIN_NAME}images/clinicianImages/margot.png`}
                  alt="Margot"
                />
              </div>
              <div className="d-flex justify-content-between audioContainer-footer  align-items-center ross-footer">
                <p
                  className="clinicain-name"
                  id="main2"
                  onMouseEnter={() =>
                    clinician[1].selected || values.audio_preference == "margot"
                      ? null
                      : ontargetMouse("margot")
                  }
                  onMouseOut={() =>
                    clinician[1].selected || values.audio_preference == "margot"
                      ? null
                      : outtargetMouse("margot")
                  }
                >
                  MARGOT
                </p>
              </div>
            </div>
            <div className="mediaButton-container">
              <div onClick={() => handleClinician("margot")}>
                {audioPreview[1].preview == true &&
                clinician[1].selected == true ? (
                  <img
                    className="selectedPause"
                    src={`${selectedPause}`}
                    alt="casual-park"
                  />
                ) : audioPreview[1].preview == false &&
                  clinician[1].selected == true ? (
                  <img
                    className="selectedPlay"
                    src={`${selectedPlay}`}
                    alt="casual-park"
                  />
                ) : audioPreview[1].preview == true &&
                  clinician[1].selected == false ? (
                  <img
                    className="normalPuase"
                    src={`${margotPauseButon}`}
                    alt="casual-park"
                  />
                ) : (
                  <img
                    className="normalPlay"
                    src={`${margotPlayButton}`}
                    alt="casual-park"
                  />
                )}
              </div>
              <audio
                id="myAudio2"
                controls=""
                onEnded={() => handleAudioEnd("ross")}
              >
                <source src={`${margot}`} type="audio/mp3"></source>
              </audio>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            className="main-audio-container"
          >
            <div
              className={
                clinician[2].selected || values.audio_preference == "tony"
                  ? "audio-cardContainer justify-content-between clinician-selected"
                  : "audio-cardContainer justify-content-between"
              }
              onClick={() => handleClinician("tony")}
            >
              <div className="audio-imageContainer text-center">
                <img
                  className="imageStyle"
                  width="350px"
                  height="444px"
                  // src={`${DOMAIN_NAME}images/login/login8464.png`}
                  src={`${DOMAIN_NAME}images/clinicianImages/Anthony.png`}
                  alt="Anthony"
                />
              </div>
              <div className="d-flex justify-content-between audioContainer-footer  align-items-center ross-footer">
                <p
                  className="clinicain-name"
                  id="main3"
                  onMouseEnter={() =>
                    clinician[2].selected || values.audio_preference == "tony"
                      ? null
                      : ontargetMouse("tony")
                  }
                  onMouseOut={() =>
                    clinician[2].selected || values.audio_preference == "tony"
                      ? null
                      : outtargetMouse("tony")
                  }
                >
                  ANTHONY
                </p>
              </div>
            </div>
            <div className="mediaButton-container">
              <div onClick={() => handleClinician("tony")}>
                {audioPreview[2].preview == true &&
                clinician[2].selected == true ? (
                  <img
                    className="selectedPause"
                    src={`${selectedPause}`}
                    alt="casual-park"
                  />
                ) : audioPreview[2].preview == false &&
                  clinician[2].selected == true ? (
                  <img
                    className="selectedPlay"
                    src={`${selectedPlay}`}
                    alt="casual-park"
                  />
                ) : audioPreview[2].preview == true &&
                  clinician[2].selected == false ? (
                  <img
                    className="normalPuase"
                    src={`${tonyPauseButton}`}
                    alt="casual-park"
                  />
                ) : (
                  <img
                    className="normalPlay"
                    src={`${tonyPlayButton}`}
                    alt="casual-park"
                  />
                )}
              </div>
              <audio
                id="myAudio3"
                controls=""
                onEnded={() => handleAudioEnd("ross")}
              >
                <source src={`${anthony}`} type="audio/mp3"></source>
              </audio>
            </div>
          </div>
        </div>
        <div className="error-box-reponsive">
          {values.audio_preference === "" && (
            <small className="text-danger input-label-style error-message-style">
              {emptyCheck.audio_preference}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserClinicianInfo;
