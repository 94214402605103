import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7thirdpage/part7thirdpage.css";
import "../../../../styles/part_6/part6secondpage/part6secondpage.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part6SecondPage = () => {
  /*******   Helper Functions  *******/
  const [isPlaying, setIsPlaying] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /********** functions********/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_6/sar", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/gaining_control`, { replace: true });
  };

  /*******   useEffect *******/

  useEffect(() => {
    ModuleCompletionEmails("mp3_file_download_email");
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Attentional Training (ATT) | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body  p-0 mb-0">
                  <div className="title page-title-style mb-0">
                    My 1st Listening Exercise
                  </div>
                  <div className="card-body">
                    <p className="component-heading-style">‘Zone @ home’</p>
                    <div className="main-container my-3">
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part6/page6secondPage/248.jpg`}
                            alt="zone at home"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part6/page6secondPage/249.png`}
                            alt="zone at home"
                          />
                        </div>
                      </div>
                    </div>

                    <p>
                      ZONE @ HOME is a psychological workout that disrupts
                      negative patterns of thinking by strengthening your
                      ability to refocus your attention on different sounds.
                    </p>
                    <h2 className="attText">
                      <a
                        // href={{
                        //   ross: `${DOMAIN_NAME}public/audio/clinician/default/ZoneHomedownload.mp3`,
                        //   margot: `${DOMAIN_NAME}public/audio/clinician/default/ZoneHomedownloadmargot.mp3`,
                        //   anthony: `${DOMAIN_NAME}public/audio/clinician/default/ZonehomedownloadAnthony.mp3`,
                        // }[user?.userInfo?.audio_preference]}
                        href={
                          {
                            ross: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZoneHomedownload.mp3",
                            margot:
                              "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZoneHomedownloadmargot.mp3",
                            tony: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZonehomedownloadAnthony.mp3",
                          }[`${user?.userInfo?.audio_preference}`]
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here to listen to or download this exercise
                      </a>
                    </h2>
                    <p className="component-heading-style">
                      ZONE @ HOME has 3 parts
                    </p>
                    <div className="main-container">
                      <div className="image-third-container">
                        <img
                          src={`${DOMAIN_NAME}images/part6/page6secondPage/250.png`}
                          alt="situations"
                        ></img>
                      </div>
                      <div className="image-third-container">
                        <img
                          src={`${DOMAIN_NAME}images/part6/page6secondPage/251.png`}
                          alt="situations"
                        ></img>
                      </div>
                      <div className="image-third-container">
                        <img
                          src={`${DOMAIN_NAME}images/part6/page6secondPage/252.png`}
                          alt="situations"
                        ></img>
                      </div>
                    </div>
                    <div className="main-container my-5 ">
                      <div
                        className=" sub-container center-align"
                        style={{ maxWidth: "650px" }}
                      >
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part6/page6secondPage/253.png`}
                            alt="Stress Release tips"
                            // onMouseOver={handleMouseOver}
                            // onMouseOut={handleMouseOut}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part6SecondPage;
