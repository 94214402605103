import React, { useEffect, useState } from "react";

// import superStrong from "../../public/Images/registration/SuperStrong.png";
// import superResilient from "../../public/Images//registration/SuperSilient.png";
// import superCalm from "../../public/Images/registration/SuperCalm.png";
// import superBrave from "../../public/Images/registration/SuperBrave.png";
// import superFearless from "../../public/Images/registration/SuperFearless.png";
// import superSmart from "../../public/Images/registration/SuperSmart.png";
import { DOMAIN_NAME } from "../../env/Baseurl";
import { Tooltip } from "@mui/material";

const UserSuperHeroInfo = ({
  values,
  emptyCheck,
  handleSuperHeroSelection,
}) => {
  const [superHero, setSuperHero] = useState("");
  const [currentState, setCurrentState] = useState(0);
  const [index, setIndex] = useState(0);
  var allItems = document.getElementsByClassName("crousal-item");

  useEffect(() => {
    handleSlide(handleSelectedSlicer());
  }, []);

  useEffect(() => {
    handleSlide(index);
  }, [index]);

  const handleSelectedSlicer = () => {
    switch (values.superherosect) {
      case "superStrong":
        return 0;
        break;
      case "superResilient":
        return 1;
        break;
      case "superCalm":
        return 2;
        break;
      case "superBrave":
        return 3;
        break;
      case "superFearless":
        return 4;
        break;
      case "superSmart":
        return 5;
        break;
      default:
        return 0;
    }
  };

  const handleSlide = (state) => {
    // console.log(currentState, "currentState");
    // console.log(state, "clicked state");

    if (currentState > state) {
      for (var i = currentState; i >= state; i--) {
        let str = "";
        let opacity = "";
        if (i === state) {
          str = "0%";
          opacity = "1";
        } else {
          str = "120%";
          opacity = "0.3";
        }
        allItems[i].style.transform = `translateX(${str})`;
        allItems[i].style.transition = "all .3s";
        allItems[i].style.opacity = opacity;
        if (state > 0) {
          allItems[i - 1].style.opacity = "0.3";
          //   allItems[i - 1].style.display = "flex";
        }
        // if (state > 1) {
        //     for (var z = state - 2; z >= 0; z--) {
        //         allItems[z].style.opacity = '0'
        //     }
        // }
        if (state < 4) {
          for (let x = state + 2; x <= 5; x++) allItems[x].style.opacity = "0";
        }
        // if (state <= 4) {
        //   for (var x = 4; x >= state + 1; x--) {
        //     allItems[x].style.display = "none";
        //   }
        // }
        // if (state <= 4) {
        //   for (var y = 4; y >= 0; y--) {
        //     // allItems[y].style.display = "flex";
        //     // allItems[y + 1].style.opacity = '1';
        //   }
        // }
      }
      setCurrentState(state);
    } else if (currentState < state) {
      for (var i = currentState; i <= state; i++) {
        let str = "";
        let opacity = "";
        if (i === state) {
          str = "0%";
          opacity = "1";
        } else {
          str = "-120%";
          opacity = "0.3";
        }
        allItems[i].style.transform = `translateX(${str})`;
        allItems[i].style.transition = "all .3s";
        allItems[i].style.opacity = opacity;
        if (state < 5) {
          allItems[i + 1].style.opacity = "0.3";
        }
      }
      // if (state >= 1 && state <= 4) {
      //     for (var x = state - 1; x <= state + 1; x++) {
      //         allItems[x].style.display = 'flex';
      //     }
      // }
      // if (state == 5) {
      //     for (var x = state - 1; x <= state; x++) {
      //         allItems[x].style.display = 'flex';
      //     }
      // }
      if (state > 1) {
        for (var y = 0; y < state - 1; y++) {
          //   allItems[y].style.display = "none";
          allItems[y].style.opacity = "0";
        }
      }
      setCurrentState(state);
    } else {
    }
  };

  const handleSelection = (name) => {
    handleSuperHeroSelection(name);
    // setSuperHero(name);
    // console.log(name);
    // setArrayofSuperHero();
  };

  return (
    <div className="row justify-content-center" style={{ marginTop: "50px" }}>
      <div className="col-8 col-md-8 col-lg-8">
        <h3 className="text-center pagehead-texttitle">
          Meet the superheroes!
        </h3>
        <p className="pagehead-textsubtitle input-label-style text-center">
          Select the one you like the best to follow you throughout the program.
          Your superhero will give you information and tips along the way!
        </p>
      </div>
      <div className="col-12 mainCrousal-container">
        <div className="crousal-container">
          <div className="crousal-item crousal-item-0">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/superStrong.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERSTRONG</h3>
              <p className="superHero-des">
                SuperStrong is a brave and fearless character. They have the
                power to not care what other people think of them. SuperStrong
                fights off bullies by ignoring everything they say.
              </p>
              <button
                className={
                  values.superherosect == "superStrong"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superStrong")}
              >
                {values.superherosect == "superStrong"
                  ? "Selected"
                  : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-item crousal-item-1">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/SuperSilient1.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERRESILIENT</h3>
              <p className="superHero-des">
                SuperResilient is powerful. They move towards their fears and
                into uncomfortable situations. SuperResilient can brush off
                things that go wrong with ease.
              </p>
              <button
                className={
                  values.superherosect == "superResilient"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superResilient")}
              >
                {values.superherosect == "superResilient"
                  ? "Selected"
                  : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-item crousal-item-2">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/SuperCalm1.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERCALM</h3>
              <p className="superHero-des">
                SuperCalm is a cool and graceful character. They have the power
                to ignore the negative chatter in their mind and stay in the
                present. SuperCalm continues to pursue their interests even when
                roadblocks get in the way.
              </p>
              <button
                className={
                  values.superherosect == "superCalm"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superCalm")}
              >
                {values.superherosect == "superCalm" ? "Selected" : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-item crousal-item-3">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/SuperBrave.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERBRAVE</h3>
              <p className="superHero-des">
                SuperBrave is a courageous and brainy character. They have the
                power to accept the things they can’t change. SuperBrave accepts
                people as they are. They recognise not everyone likes them but
                they seem unmoved or unconcerned by this.
              </p>
              <button
                className={
                  values.superherosect == "superBrave"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superBrave")}
              >
                {values.superherosect == "superBrave"
                  ? "Selected"
                  : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-item crousal-item-4">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/SuperFearless.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERFEARLESS</h3>
              <p className="superHero-des">
                SuperFearless is unafraid of anything. SuperFearless can speak
                confidently in front of groups of people, to teachers, powerful
                people, and strangers. They don’t care if they stutter when
                speaking.
              </p>
              <button
                className={
                  values.superherosect == "superFearless"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superFearless")}
              >
                {values.superherosect == "superFearless"
                  ? "Selected"
                  : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-item crousal-item-5">
            <div className="superHero-image-container">
              <img
                className="imageStyle"
                src={`${DOMAIN_NAME}images/superheroImages/SuperSmart1.png`}
                alt="super-Hero"
              />
            </div>
            <div className="superHero-detail-container">
              <h3 className="superHero-title">SUPERSMART</h3>
              <p className="superHero-des">
                SuperSmart is a powerhouse. SuperSmart is a hardworking
                scientist who conducts experiments everyday by facing their
                fears. They never avoid talking to friends, workmates, or
                powerful people and work towards their goals every day.
              </p>
              <button
                className={
                  values.superherosect == "superSmart"
                    ? "btn btn-block btn-sm select-button superHero-selected-button "
                    : " btn btn-block btn-sm select-button"
                }
                style={{
                  fontSize: "20px",
                  lineHeight: "21px",
                  height: "53px",
                  width: "134px",
                }}
                onClick={() => handleSelection("superSmart")}
              >
                {values.superherosect == "superSmart"
                  ? "Selected"
                  : "Select Me"}
              </button>
            </div>
          </div>
          <div className="crousal-nav">
            <Tooltip
              title={index !== 0 ? "Previous" : ""}
              placement="top"
              arrow
            >
              <button
                style={index === 0 ? { pointerEvents: "none" } : null}
                onClick={() => {
                  if (index === 0) return;
                  setIndex((prev) => prev - 1);
                }}
              >
                <svg width="75px" height="75px" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#777"
                    stroke-width="1"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.26 15.53L9.73999 12L13.26 8.46997"
                    stroke="#777"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title={index !== 5 ? "Next" : ""} placement="top" arrow>
              <button
                style={index === 5 ? { pointerEvents: "none" } : null}
                onClick={() => {
                  if (index === 5) return;
                  setIndex((prev) => prev + 1);
                }}
              >
                <svg width="75px" height="75px" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#777"
                    stroke-width="1"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.74 15.53L14.26 12L10.74 8.46997"
                    stroke="#777"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
        <div
          style={{ width: "988px", marginTop: "20px" }}
          className="erorbox-Superhero"
        >
          {values.superherosect === "" && (
            <small className="text-danger input-label-style text-left error-message-style">
              {emptyCheck.superherosect}
            </small>
          )}
        </div>
        <div className="indicator-container">
          <div
            className={
              currentState === 0 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(0)}
          ></div>
          <div
            className={
              currentState === 1 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(1)}
          ></div>
          <div
            className={
              currentState === 2 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(2)}
          ></div>
          <div
            className={
              currentState === 3 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(3)}
          ></div>
          <div
            className={
              currentState === 4 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(4)}
          ></div>
          <div
            className={
              currentState === 5 ? "active-dot-item" : "indicator-item dots"
            }
            onClick={() => handleSlide(5)}
          ></div>
        </div>
      </div>
      {/* <div style={{ width: "988px", }}>
        {
          values.superherosect === "" && (
            <small className="text-danger input-label-style text-left">
              {emptyCheck.superherosect}
            </small>
          )
        }
      </div> */}
    </div>
  );
};

export default UserSuperHeroInfo;
