import CheckboxSituations from "../templates/CheckboxSituations";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import { GetCheckboxes } from "../../../../services/part3/get-checkboxes";
import { Helmet } from "react-helmet";
import React from "react";

const AnxietySymptoms = () => {
  const [checkboxes, errorMessage] = GetCheckboxes("physiological"); // Checkboxes list from API
  /*******   Page Params  *******/
  const title = "My Body's Response";
  const imageTitle = `Ben’s model with his 'physiological symptoms' of anxiety highlighted BLUE`;
  const checkboxQuestion =
    "Now tick the boxes for the physiological symptoms you experience when you get anxious. Please select at least one component:";
  const link = "/part_3/self-focussed_imagery";
  const validationMessage = "Please select al least one physiological symptom.";
  const imageSrc = `${DOMAIN_NAME}images/part3/graphs/Social3.jpg`;
  /*******   Render  *******/

  return (
    <>
      <Helmet>
        <title> My Body's Response | ASRC iBroadway</title>
      </Helmet>
      <CheckboxSituations
        title={title}
        imageTitle={imageTitle}
        body=""
        checkboxQuestion={checkboxQuestion}
        link={link}
        checkboxeslist={checkboxes}
        type={"create_physiological"}
        validationMessage={validationMessage}
        imageSrc={imageSrc}
        error={errorMessage}
        previousLink="part_3/safety_behaviours"
      />
    </>
  );
};

export default AnxietySymptoms;
