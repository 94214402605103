import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/completePage/completePage.css";

// import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { createElement, useEffect, useState } from "react";
import { toBlob, toJpeg, toPixelData, toPng, toSvg } from "html-to-image";

import { Bar } from "react-chartjs-2";
import { CompletePageUserResult } from "../../../../services/post-treatment/complete-page-user-result";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import LastModuleBadge from "../../../../components/global/LastModulebadge";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import SendProgramCompleteEmail from "../../../../services/post-treatment/send-program-complete-email";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import celebration from "../../../../assets/GIf/Celebration.gif";
import close from "../../../../assets/GIf/close.svg";
import download from "../../../../assets/GIf/file-download.svg";
import getBadgeInfo from "../../../../actions/badgeInfo";
import { handleModuleComplete } from "../../../../services/helpers";
import play from "../../../../assets/GIf/play-circle.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

var htmlbody = "<html><body>";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CompleteProgram = (props) => {
  /**************************States****************************** */
  const [updateModule, setUpdateModule] = useState(false);
  const [userResult, errorMessage] = CompletePageUserResult(); // Get User Result from API
  const labels = ["Mood", "Social Anxiety", "Unhelpful Thoughts", "Avoidance"];
  const data = {
    labels,
    datasets: [
      {
        label: "Before",
        data: userResult.before_data,
        backgroundColor: "#ABBAFF",
      },
      {
        label: "After",
        data: userResult.after_data,
        backgroundColor: "#7183D6",
      },
    ],
  };

  /********************************Hooks & localStoarge********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /**********************Params********************* */

  const emailHtml = () => {
    if (userResult !== undefined && userResult.length !== 0) {
      {
        userResult?.result?.map((item, index) => {
          htmlbody += `<div className="left-content"><p style="color: #ff2305;font-weight:bold;font-family: Helvetica; margin-top:20px">${item.name} </p><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">Before:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.before_width};background: #ff0000" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                             </div></div><span>${item.Before} </span></div></div><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">After:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.after_width} ;background: #00FF00" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div><span> ${item.After}</span></div></div>
                             </div>`;
        });
      }
      htmlbody += "</body></html>";
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "User ",
      },
    },
    scales: {
      y: {
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const content = (
    <p className="bangerTextStyle">
      Hi {user?.userInfo?.Fname + " " + user?.userInfo?.Lname},<br></br>
      <br></br>You've reached the end. Congratulations!<br></br>
      <br></br> We hope you have benefited from doing this program. You can now
      collect your free comic book and superhero movie at the end of the page!
      <br></br>
      <br></br> The graph below shows your results from completing the
      questionnaires at the beginning and end of the program. Lower scores after
      completing this program suggest an improvement in the way you think and
      feel. <br></br>
      <br></br>please contact us if you have any questions or concerns.
      <br></br>
      <br></br>
      Cheers,
      <br></br>
      <br></br>
      iBroadway team
    </p>
  );

  const badgeContent = (
    <div>
      <p className="greetingMessage2">Congratulations!!!!</p>
      <p className="greetingMessage1">
        {" "}
        You have made it to the
        <span className="greetingMessage3"> Ultimate Reward </span>at the end of
        the Program{" "}
      </p>
      <p className="greetingMessage1">
        {" "}
        A Free <span className="greetingMessage3">Comic book</span> &{" "}
        <span className="greetingMessage3">SuperHero Movie</span>
      </p>
    </div>
  );

  const Title = "Complete";
  const pageTitle = "Complete!";

  /*******  Download comic pdf  *************************/

  const downloadCopy = () => {
    switch (user?.userInfo?.superherosect) {
      case "superStrong":
        window.open(`${DOMAIN_NAME}assets/pdf/superStrong.pdf`, "_blank");
        break;
      case "superResilient":
        window.open(`${DOMAIN_NAME}assets/pdf/superResilient.pdf`, "_blank");
        break;
      case "superCalm":
        window.open(`${DOMAIN_NAME}assets/pdf/superCalm.pdf`, "_blank");
        break;
      case "superBrave":
        window.open(`${DOMAIN_NAME}assets/pdf/superBrave.pdf`, "_blank");
        break;
      case "superFearless":
        window.open(`${DOMAIN_NAME}assets/pdf/superFearless.pdf`, "_blank");
        break;
      case "superSmart":
        window.open(`${DOMAIN_NAME}assets/pdf/superSmart.pdf`, "_blank");
        break;
      default:
      //nothing happen
    }
  };

  /*************** play video *************/

  // const playVideo = (e) => {
  //   // console.log(e, "its on play")
  //   var myVideo = document.getElementsByClassName('comic-video')[0]
  //   myVideo.classList.add("show")
  //   myVideo.play();
  //   // myVideo.style.display = "block"
  //   setIsPlaying(true)
  // }

  // const handleAudioEnd = (e) => {
  //   console.log(e, "its on end")
  //   var myVideo = document.getElementsByClassName('comic-video')[0]
  //   myVideo.classList.remove("show")

  //   // myVideo.style.display = "none"
  //   setIsPlaying(false)
  // }

  /********************** module complete status ********/

  const handleModule = async () => {
    const dataObject = {
      user_id: user?.userInfo?._id,
      module_name: "part8",
    };
    let response = await handleModuleComplete(dataObject);
    // console.log(response?.userInfo?.total_points, 'its api response')
    const updatePoins = {
      ...user,
      userInfo: {
        ...user.userInfo,
        total_points: response?.userInfo?.total_points,
      },
    };
    await localStorage.setItem("user", JSON.stringify(updatePoins));
    // console.log(updatePoins, "new points")
    setUpdateModule(true);
  };

  const myprintFunction = async () => {
    document.getElementById("printAble").style.display = "block";
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").classList.add("card", "mb-4");
    document.getElementById("printAble").innerHTML = realData;

    // this if for bargraph
    var bargraph = document.getElementById("bargraph");
    const imgData = await htmlToImage.toCanvas(bargraph);
    imgData.style.width = "1000px";
    imgData.style.height = "500px";
    document.getElementById("printAble").appendChild(imgData);

    // this for user progresss data
    document.getElementById("printFooter").style.display = "block";
    var userProgress = document.getElementById("userProgress").innerHTML;
    document
      .getElementById("printAble")
      .insertAdjacentHTML("beforeend", userProgress);
    document.getElementById("mainContainer").style.display = "none";
    window.print();
    // After print function on save or cancle
    document.getElementById("printAble").style.display = "none";
    document.getElementById("mainContainer").style.display = "block";
    // var progressData = document.getElementById('user-progress').innerHTML
    // document.getElementById('user-progress').innerHTML = progressData
    // document.getElementById('main').innerHTML = realData;
    // document.getElementById("printAble").removeChild(userProgress)
    document.getElementById("printAble").innerHTML = secondData;
    document.getElementById("printAble").classList.remove("card");
    document.getElementById("printAble").classList.remove("mb-4");
    document.getElementById("printFooter").style.display = "none";
  };

  /************************UseEffect************************ */

  useEffect(() => {
    handleModule();
  }, []);

  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => {})
      .catch(() => {});
  }, []);

  useEffect(() => {
    emailHtml();
  }, [userResult]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      SendProgramCompleteEmail(htmlbody); // Sending module completion email
    }, 6000);
  }, []);

  /**************************************Render*************** */

  return (
    <>
      <div className="dashboardContent">
        <Helmet>
          <title>Complete! | ASRC iBroadway</title>
        </Helmet>
        <div id="printAble" className=""></div>
        <div id="mainContainer" className="dashboardContent">
          <div>
            {/* <Header /> */}
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div className="row p-0 m-0">
            <div className="col-md-2  position-static pl-0">
              <div className="leftside">
                <Sidebar />
              </div>
            </div>
            <div className="col-md-10 content-widthsidebar content-wrapper">
              <div>
                <div className="row header-titlewith-text m-0">
                  <div className="col-md-6 p-0 main-title-style">
                    {pageTitle}
                  </div>
                  <div className="col-md-6 text-right p-0">
                    <span> Welcome</span> / {Title}
                  </div>
                </div>
                <div className="marginBotoom  card mb-4">
                  <div className="content-topheads-text tophead-container">
                    <div
                      className="visual-container-complete-program"
                      id="main"
                    >
                      <img
                        src={celebration}
                        className="background-image-gif"
                        alt="backgroud-visuals"
                      ></img>
                      <div className="textOver-graphics textOver-graphics-complete-program">
                        <p>{content}</p>
                      </div>
                    </div>
                  </div>
                  <div id="bargraph" className="freebook-barchat">
                    <div className="card-body p-0 mb-0">
                      <div className="heading-final title page-title-style m-0">
                        Your results for the program are below:
                      </div>
                    </div>
                    <div className="alignCenter">
                      <div className="lastBar">
                        <Bar options={options} data={data} />
                      </div>
                    </div>
                  </div>
                  <div className="main-container-header">
                    <div
                      className="main-comicContainer main-comicResouces"
                      id="userProgress"
                    >
                      {/* <p className="normal-text py-3" id="">
                        Badge:
                      </p>
                      <div class=" card-body title page-title-style">
                        Badge:
                      </div> */}
                      <div className="heading-final card-body p-0 mb-0">
                        <div className="title page-title-style m-0">Badge:</div>
                      </div>
                      <LastModuleBadge
                        currentBadge={`${DOMAIN_NAME}images/Badges/badge8.png`}
                        nextBadge=""
                        content={badgeContent}
                        progressBarData={100}
                        trophy={`${DOMAIN_NAME}images/badge_gif/Badge_8.gif`}
                        totalPoints={user?.userInfo?.total_points}
                      />
                      <div>
                        <FooterOfPrint />
                      </div>
                    </div>
                  </div>
                  <div className="main-comicResouces">
                    <div className="heading-final card-body p-0 mb-0">
                      <div className="title page-title-style m-0">
                        Free Comic Book and SuperHero Movie:
                      </div>
                    </div>
                    <div className="row comic-container">
                      <div class="video-container">
                        <div className="image-contaier">
                          <img
                            className="imageStyle"
                            src={
                              {
                                superStrong: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superStrong/metamorphosis.gif`,
                                superResilient: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superResilient/metamorphosis.gif`,
                                superCalm: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superCalm/metamorphosis.gif`,
                                superBrave: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superBrave/metamorphosis.gif`,
                                superFearless: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superFearless/metamorphosis.gif`,
                                superSmart: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superSmart/metamorphosis.gif`,
                              }[user?.userInfo?.superherosect]
                            }
                          ></img>
                        </div>
                        <div className="info-container">
                          {/* <h1 className='title'>
                          Watch Your Free Superhero Movie
                        </h1> */}
                          {/* <p className='text'>
                          Lorem ipsum dolor sit amet consectetur. Sit mauris vitae imperdiet proin. Proin fames pulvinar imperdiet sit justo turpis. Dictum hac bibendum diam eget elementum a ac lobortis velit. Quis arcu enim tortor.
                        </p> */}
                          {/* <div className='btn btn-sm btn-block button' onClick={() => playVideo(user?.userInfo?.superherosect)}> */}
                          {/* <a className='btn btn-sm btn-block button' target='_blank' href='/video'>
                          <img src={play} alt="play-button">
                          </img><span>Watch Your Free Movie</span>
                        </a> */}
                          <div
                            className="btn btn-sm btn-block button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                          >
                            <img src={play} alt="play-button"></img>
                            <span>Watch Your Free Movie</span>
                          </div>
                        </div>
                      </div>
                      <div className="pdf-container">
                        <div className="image-contaier">
                          <img
                            className="imageStyle"
                            src={
                              {
                                superStrong: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superStrong/metamorphosis.gif`,
                                superResilient: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superResilient/metamorphosis.gif`,
                                superCalm: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superCalm/metamorphosis.gif`,
                                superBrave: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superBrave/metamorphosis.gif`,
                                superFearless: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superFearless/metamorphosis.gif`,
                                superSmart: `${DOMAIN_NAME}images/part1/superHeroVisualGifs/superSmart/metamorphosis.gif`,
                              }[user?.userInfo?.superherosect]
                            }
                          ></img>
                        </div>
                        <div className="info-container">
                          {/* <h1 className='title pdf-title'>
                          Download Your Free Comic Book
                        </h1> */}
                          {/* <p className='text pdf-text'>
                          Lorem ipsum dolor sit amet consectetur. Sit mauris vitae imperdiet proin. Proin fames pulvinar imperdiet sit justo turpis. Dictum hac bibendum diam eget elementum a ac lobortis velit. Quis arcu enim tortor.
                        </p> */}
                          <div
                            onClick={downloadCopy}
                            className="btn btn-sm btn-block button pdf-button"
                          >
                            <img src={download} alt="play-button"></img>
                            <span className="pdf-but-text">
                              Download Your Comic Book
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap video modal start */}

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modalVideo"
          role="document"
        >
          <div class="modal-content video-model-content">
            <div class="modal-header">
              <h5 class="modal-title title" id="exampleModalLongTitle">
                Superhero Movie
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
                <img
                  id="close"
                  className="imageStyle"
                  src={close}
                  alt="close"
                />
              </button>
            </div>
            <div class="modal-body">
              <ReactPlayer
                url={
                  {
                    superStrong: "https://vimeo.com/851613785/4748065691",
                    superResilient: "https://vimeo.com/851615271/641fece789",
                    superCalm: "https://vimeo.com/851618269/bc37ec7d4d",
                    superBrave: "https://vimeo.com/851616798/f1bc70e0e4",
                    superFearless: "https://vimeo.com/851619924/e17971081c",
                    superSmart: "https://vimeo.com/851787030/f70b01b130",
                  }[user?.userInfo?.superherosect]
                }
                controls
                width="100%"
                height="500px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompleteProgram;
