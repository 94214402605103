import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part3/letxRecap/letxRecap.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LetsRecap = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/causal_thoughts_and_safety_behaviours", {
      replace: true,
    });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/another_example_of_safety_behaviours`, { replace: true });
  };

   // //Function to print
   const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> Uncovering Catastrophic Predictions | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style ">
                    Uncovering Catastrophic Predictions
                  </div>
                  <div className="card-body">
                    <p>
                      Below is a conversation Susan had with her coach to help
                      uncover her unknown prediction of what she believed would
                      happen if she stuttered in public:
                    </p>
                    <div className="main-container my-5">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/162.jpg`}
                            alt="susan with coach"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="main-container my-5 letxRecap">
                      <div className="sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/163.jpg`}
                            alt="conversation 1"
                          />
                        </div>
                      </div>
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/164.png`}
                            alt="conversation 2"
                          />
                        </div>
                      </div>
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/165.png`}
                            alt="conversation 3"
                          />
                        </div>
                      </div>
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/166.png`}
                            alt="conversation 4"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row example-container">
                      <div className="image-box">
                        <div className="main-container">
                          <div className="sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${DOMAIN_NAME}images/part3/let_recap/167.png`}
                                alt="catastrophic prediction"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-box">
                        <p className="component-heading-style">
                          Susan engaged in safety behaviours so her catastrophic
                          prediction never came true.{" "}
                        </p>
                        <p>
                          However, when Susan’s coach encouraged her to test
                          this prediction out and talk in longer sentences
                          around her team mates, Susan discovered that her
                          prediction was wrong.
                        </p>
                      </div>
                    </div>
                    <p>
                      If you don’t know why you’re feeling anxious about certain social situations, try using the “What would be so bad about that” technique like Susan and her coach did. This will help uncover any catastrophic predictions. Once uncovered, you can then do what these predictions fear most, testing their accuracy.
                    </p>
                    {/* <div className="main-container letxRecap">
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/163.png`}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/164.png`}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/165.png`}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/let_recap/166.png`}
                            alt="image"
                          />
                        </div>
                      </div>

                    </div> */}
                  </div>
                  <div>
                                        <FooterOfPrint />
                                    </div>
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LetsRecap;
