import "../../../../styles/part2/challengeExample/challengeExample.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router";

const ChallengeExample = () => {
  const moduleName = localStorage.setItem("moduleName", "Part 2");
  const part1Accessed = localStorage.setItem("part2Accessed", "true");
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  const [brainDeceive, setBrainDeceive] = useState(false);
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  /*******   Page Params  *******/

  // const title = "Part 2: Challenge Your Thinking";
  const title1 = "Part 2: My Thoughts";
  const title2 = "The Dark Side of Negative Thinking";
  const title3 = "Challenge My Thinking - Example Exercise";

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_2/challenge_1", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/complete`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

   // //Function to print
   const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Part 2: My Thoughts | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title1}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p>
                              Well done{" "}
                              <span className="normal-text-black">
                                {user?.userInfo?.Fname +
                                  " " +
                                  user?.userInfo?.Lname}
                              </span>
                            </p>
                            <p>
                              You’ve finished the first part of your program,
                              which showed the power that thoughts have over
                              feelings. Let’s continue improving these new
                              neural pathways for confidence by having a look at
                              how to take control of negative thoughts.
                            </p>
                            <p
                              className="component-heading-style"
                              style={{ marginBottom: "0px" }}
                            >
                              BUT WHY LOOK AT NEGATIVE THOUGHTS?
                            </p>
                            <p>Remember:</p>
                            <div className="main-container my-5 thought">
                              <div
                                className=" sub-container center-align thought-sub-container"
                                style={{ width: "70%", maxWidth: "835px" }}
                              >
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part2/Target_thoughts.jpg`}
                                    alt="thoughts"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 1 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 1) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 1 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title2}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p>
                              Social Anxiety will try to bring you over to the
                              dark side with 2 common mind tricks:
                            </p>

                            <div className="row m-0 dark-side my-5">
                              <div className="col-xl-7 image">
                                <img
                                  src={`${DOMAIN_NAME}images/part2/darkSide/Group 80.jpg`}
                                  alt="mind-trick"
                                />
                              </div>
                              <div className="col-xl-5 content">
                                <div>
                                  <p className="text1">
                                    Even though{" "}
                                    <span className="text2">
                                      your brain is very powerful,
                                    </span>{" "}
                                    it tends to search for the worst-case
                                    scenario and trick us into thinking it’s
                                    going to happen.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row example-container-reverse challenge-example-reverse">
                                <div className="text-box">
                                  <p className="component-heading-style">
                                    Don’t believe everything you think!{" "}
                                  </p>
                                  <p>
                                    Look at this simple example below to see how
                                    our brain can deceive us:<br></br>
                                    Which is the longest line?
                                  </p>
                                  <p>Click the button to show the answer</p>
                                  <Button
                                    className="user-reponse-submit btn btn-primary link-sty m-0"
                                    onClick={() =>
                                      setBrainDeceive(!brainDeceive)
                                    }
                                  >
                                    Answer
                                  </Button>
                                </div>
                                <div className="image-box">
                                  <div className="main-container">
                                    <div className="sub-container center-align">
                                      <div className="image-container">
                                        <img
                                          src={`${DOMAIN_NAME}images/part2/Line.jpg`}
                                          alt="lines"
                                          width=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: "100%" }}>
                                {brainDeceive && (
                                  <div className="row m-0 status">
                                    <div className="col-lg-6 text-center">
                                      <img
                                        style={{ width: "90%" }}
                                        src={`${DOMAIN_NAME}images/part2/lLine1.jpg`}
                                        alt="status"
                                      />
                                    </div>
                                    <div className="col-lg-6 text-center">
                                      <img
                                        style={{ width: "90%" }}
                                        src={`${DOMAIN_NAME}images/part2/Line2.jpg`}
                                        alt="court"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="component-sub-heading-style">
                              How did you go?
                            </p>
                            <p>
                              Our minds can be easily tricked. The next page
                              you’ll learn a simple way to test if your thoughts
                              have been hijacked and tricked by the dark side.
                            </p>
                            {/* </div> */}
                          </div>
                          <div className="card-body p-0 mb-0 mt-4">
                            <div className="title page-title-style m-0">
                              Take My Thought to Court
                            </div>
                            <div className="card-body">
                              <div className="row m-0 dark-side  court my-5 ">
                                <div className="col-xl-4 image">
                                  <img
                                    src={`${DOMAIN_NAME}images/part2/Objection.jpg`}
                                    alt="objection"
                                  />
                                </div>
                                <div className="col-xl-8 content">
                                  <div>
                                    <p className="component-heading-style">
                                      Take My Thought to Court
                                    </p>
                                    <p>
                                      Imagine being a lawyer (or Judge Judy) and
                                      taking your Negative thought to court.
                                      Sounds weird I know, but stay with me.
                                    </p>
                                    <p>
                                      When people are cross-examined in court,
                                      they are asked a series of questions to
                                      see if they are telling the truth or not.
                                      We’ll do the same with negative thoughts
                                      to see how accurate they really are.
                                    </p>
                                    <div className="row m-0 status">
                                      <div className="col-lg-6">
                                        <img
                                          src={`${DOMAIN_NAME}images/part2/Statue.jpg`}
                                          alt="status"
                                        />
                                      </div>
                                      <div className="col-lg-6">
                                        <img
                                          src={`${DOMAIN_NAME}images/part2/Court.jpg`}
                                          alt="court"
                                        />
                                      </div>
                                    </div>
                                    {/* <img src={`${DOMAIN_NAME}images/part2/Objection.jpg`}
                                                        alt="thoughts" /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 2 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 2) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 2 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title3}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <div>
                              <p className="">
                                Below is an example of thought challenging. The
                                questions below will help you attack the
                                negative thoughts that you ticked in the opening
                                questionnaire in this program.
                              </p>
                              <p className="text-center component-sub-heading-style ">
                                EXAMPLE CAUSAL THOUGHT
                              </p>
                              <p className="text-center component-sub-heading-style ">
                                Other people will think I am stupid if I stutter
                              </p>
                              <div className="content-wrap my-4 example-exerise">
                                <div className="content-topheads-text">
                                  <div className="left-content">
                                    <div className="row m-0">
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            1. What evidence do you have for
                                            thought?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className="sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_1.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            2.What evidence do you have against
                                            the thought?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_2.jpeg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            3. What would you say to help a
                                            friend who had the thought?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_3.jpeg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            4. Think of someone you know who is
                                            really chilled and confident. What
                                            would they say to this thought?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_4.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            5. Are you worring about an outcome
                                            that is out of your control?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_5.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            6. What does the thought do for you?
                                            How does it make you feel? Is it
                                            helpful in any way?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_6.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            7. What good things would you gain
                                            if you gave up believing in the
                                            thought?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_7.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 " width="40%">
                                        <div className="question-container">
                                          <p className="questions component-heading-style text-center mb-0">
                                            8. If the casual thought is true,
                                            What's the world outcome? Could you
                                            handle it?
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${DOMAIN_NAME}images/part2/Answer_8.jpg`}
                                                alt="hannah crossing street"
                                                width=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                  <div className="previous col-5 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-7 px-0">
                    <Button
                      disabled={buttonState}
                      className="user-reponse-submit btn btn-rimary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeExample;
