import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAllImageryResponse } from "../../../../services/part5/create_imagery_response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import idea from "../../../../assets/tip/idea.png";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part6FifthPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   Helper Functions  *******/

  const [imagery, errorMessage] = GetAllImageryResponse();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_6/more_observer_bias", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/sar_responses`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Don't Trust My Memory | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-3">
                    Don't Trust My Memory
                  </div>
                  <div className="card-body">
                    <div className="row example-container-reverse mt-0 zone-home-technique">
                      <div className="text-box">
                        <p>
                          Research shows that people with social anxiety
                          unknowingly put incorrect and distorted images into
                          their memories.
                          <span className="blueText">
                            {" "}
                            That’s right, they make up parts of their past
                            memories about distressing social situations without
                            evening knowing it.
                          </span>
                          <span className="normal-text-black">
                            {" "}
                            Confused? Check this example out:
                          </span>
                        </p>
                        <p>
                          A few weeks ago Drew was talking to 3 girls on a day
                          when his stuttering severity was very high. He really
                          liked one of the girls, Sally. But the more he tried
                          to keep up his end of the conversation, the more he
                          blocked.
                        </p>
                      </div>
                      <div className="image-box">
                        <div className="main-container">
                          <div className="sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${DOMAIN_NAME}images/part6/part6fifthPage/265.png`}
                                alt="Stress Release tips"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row example-container">
                      <div className="image-box">
                        <div className="main-container">
                          <div className="sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${DOMAIN_NAME}images/part6/part6fifthPage/266.jpg`}
                                alt="hannah crossing street"
                                width=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-box">
                        <p className="component-heading-style">
                          Drew was shattered by the way it all went and he has
                          images of that embarrassing day burnt into his memory.
                        </p>
                      </div>
                    </div>
                    <p className="mb-0">
                      Here’s the cool part, the images Drew reported seeing in
                      his mind are false!
                    </p>
                    <p className="normal-text-black mb-0 ">Why?</p>
                    <p className="mb-0">
                      This is the scene Drew described that has burnt in his
                      memory from his embarrassing day.
                      <span className="normal-text-black">
                        {" "}
                        Can you spot the problem?
                      </span>
                    </p>
                    <p className="mb-0">
                      How did you go? The answer is that it’s impossible for
                      Drew to see himself in the picture. We can only see a
                      memory from our own eyes, not from the angle of a camera
                      standing along side us.
                    </p>
                    <p className="mb-0">
                      This is more the scene that Drew would have seen on the
                      day.
                    </p>
                    <div className="main-container my-5 ">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part6/part6fifthPage/267.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="component-heading-style">
                      What do false memories do?
                    </p>
                    <p>
                      Drew’s distress from that day is being magnified by a
                      false memory.{" "}
                      <span className="blueText">
                        {" "}
                        What’s more his false memory is also lowering his social
                        confidence in present day social encounters.
                      </span>
                    </p>
                    <p>
                      <span className="normal-text-black">
                        Do you think you show this “external camera” bias with
                        your memories?
                      </span>{" "}
                      Earlier in the program you told us that you do get images
                      in your mind of the following:
                    </p>
                    <ul class="listing">
                      {imagery && imagery.length > 0
                        ? imagery?.map((item) => {
                            return <li key={item?._id}>{item?.question}</li>;
                          })
                        : null}
                    </ul>
                    <p>
                      Recall these images above and the uncomfortable social
                      situation that caused them. Now, in these images, if you
                      see yourself from an “external camera” view (like someone
                      else is watching you) then you know it’s a false memory.
                      <span className="blueText">
                        {" "}
                        Remember, unless you are telling us that you were
                        standing in front of a large mirror in these social
                        events, then you simply could not see yourself doing
                        anything.
                      </span>
                    </p>
                    <p>How cool is that!</p>
                    <div className="tip-container">
                      <div className="tip-image-container">
                        <img
                          className="part6_bar_image"
                          src={tipBackground}
                          alt="tip-background"
                          width="923px"
                        />
                      </div>
                      <div className="idea">
                        <img className="part6_bar_image" src={idea} alt="tip" />
                      </div>
                      <div className="tip-content">
                        <span className="whiteText">
                          If you can see yourself in your distressing memory,
                          then the memory is false.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part6FifthPage;
