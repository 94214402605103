import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SocialAnxiety = () => {
  /*******   Helper Functions  *******/
  const [isPlaying, setIsPlaying] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/bens_model_2", { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/more_safety_behaviours`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> A Model of Ben's Social Anxiety | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div
                    className="title page-title-style mb-0"
                    style={{ marginBottom: "0px" }}
                  >
                    A Model of Social Anxiety
                  </div>
                  <div className="card-body">
                    <p>
                      Ben is going to an under 18’s dance party and he’s worried
                      people might hear him stutter. Check out his negative
                      causal thoughts below:
                    </p>
                    <div className="main-container my-5">
                      <div className="sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/174.png`}
                            alt="negative casuals"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row situation-box">
                      <div className="col-lg-6 p-0 px-lg-2 d-flex align-items-center">
                        <div>
                          <p className="component-heading-style">
                            A model of social anxiety
                          </p>
                          <p className="">
                            Ben’s causal thoughts will determine how he’ll go
                            talking to people at the dance party. Look at Ben’s
                            model to the right. He enters a feared situation,
                            which activates his causal thoughts of: I’m going to
                            stutter; I’ll feel embarrassed; They’ll think I’m a
                            nervous person; They’ll think I’m weird. These
                            thoughts lead to a prediction of social danger (if I
                            stutter no-one will speak to me), which contributes
                            to self focusing, safety behaviours and physical
                            anxiety symptoms (e.g. sweating). On the next page
                            we’ll see this model personalized for Ben.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 p-0 p-lg-2  d-flex justify-content-center social-anxiety-model">
                        <div>
                          <img
                            style={{ width: "100%" }}
                            src={`${DOMAIN_NAME}images/part3/graphs/Social.jpg`}
                            alt="social anxiety"
                          />
                        </div>
                        {/* <div className="img-how-to-sty">
                          <img className="checkboxImages" src={`${DOMAIN_NAME}images/part3/graphs/Social.jpg`} alt="Social Anxiety Model" />
                        </div> */}
                      </div>
                    </div>
                    {/* <p className="component-heading-style">A model of social anxiety</p>
                    <div className="main-container my-5">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part3/graphs/Social.jpg`}
                            alt="social anxiety"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      Ben’s causal thoughts will determine how he’ll go talking
                      to people at the dance party. Look at Ben’s model to the
                      right. He enters a feared situation, which activates his
                      causal thoughts of: I’m going to stutter; I’ll feel
                      embarrassed; They’ll think I’m a nervous person; They’ll
                      think I’m weird. These thoughts lead to a prediction of
                      social danger (if I stutter no-one will speak to me),
                      which contributes to self focusing, safety behaviours and
                      physical anxiety symptoms (e.g. sweating). On the next
                      page we’ll see this model personalized for Ben.
                    </p> */}
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialAnxiety;
