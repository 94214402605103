import React from 'react';
import { Helmet } from 'react-helmet';
import CheckboxSituations from '../templates/CheckboxSituations';
import { GetCheckboxes } from '../../../../services/part3/get-checkboxes';
import { DOMAIN_NAME } from '../../../../env/Baseurl';

const SelfFocusedImagery = () => {
    const [checkboxes, errorMessage] = GetCheckboxes('imagery');         // Checkboxes list from API
    /*******   Page Params  *******/
    const title = "My Self Images";
    const imageTitle = `Ben’s model with his 'imagined self images' highlighted BLUE`
    const checkboxQuestion = "Tick any of the following components that are present in your ‘imagined self images’ or how you imagine you’re coming across when anxious in social situations:";
    const link = "/part_3/your_model";
    const validationMessage = "Please select at least one mental image you have had.";
    const imageSrc = `${DOMAIN_NAME}images/part3/graphs/Social2.jpg`;
    /*******   Render  *******/

    return (
        <>
            <Helmet>
                <title> My Self Images | ASRC iBroadway</title>
            </Helmet>
            <CheckboxSituations
                title={title}
                imageTitle={imageTitle}
                body=''
                checkboxQuestion={checkboxQuestion}
                link={link}
                checkboxeslist={checkboxes}
                type={'create_imagery'}
                validationMessage={validationMessage}
                imageSrc={imageSrc}
                error={errorMessage}
                previousLink='part_3/physiological_anxiety_symptoms'
            />
        </>
    );
};

export default SelfFocusedImagery;