import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { DOMAIN_NAME } from "../../env/Baseurl";
import "../../styles/userBadge/userBadge.css";
import "../../styles/dashboard-style.css";
import getBadgeInfo from "../../actions/badgeInfo";
import { useDispatch, useSelector } from "react-redux";

const UserBadge = () => {
  /*******************States**********************************/

  /*************************Hooks and LocalStorage*********/

  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*********************Redux*****************************/

  const { badge } = useSelector((state) => state.badge);
  // console.log(badge, 'user badge info in the userbadge page')
  const { message } = useSelector((state) => state.message);

  /****************************UseEffect***************** */

  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => { })
      .catch(() => { });
  }, []);

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          <Header />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>

          <div className="col-md-10 content-widthsidebar content-wrapper">
            {/* <p className="main-title-style">Your Badges </p> */}
            <div className="card">
              <div className="card-body p-0">
                <div id="main">
                  <div className=" title page-title-style mb-0">
                    Your Badges
                  </div>
                  <div className="main-badge-container">
                    <div className="row badge-container">
                      <div className=" col-12 col-xl-4">
                        <div class="current-badge">
                          <p className="component-heading-style">
                            Your Current Badge & Points
                          </p>
                          <div>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module == 0
                                  ? "image-style-blur"
                                  : null
                                }`}
                              src={
                                {
                                  0: `${DOMAIN_NAME}images/Badges/badge1.png`,
                                  1: `${DOMAIN_NAME}images/Badges/badge1.png`,
                                  2: `${DOMAIN_NAME}images/Badges/badge2.png`,
                                  3: `${DOMAIN_NAME}images/Badges/badge3.png`,
                                  4: `${DOMAIN_NAME}images/Badges/badge4.png`,
                                  5: `${DOMAIN_NAME}images/Badges/badge5.png`,
                                  6: `${DOMAIN_NAME}images/Badges/badge6.png`,
                                  7: `${DOMAIN_NAME}images/Badges/badge7.png`,
                                  8: `${DOMAIN_NAME}images/Badges/badge8.png`,
                                }[badge?.userInfo?.complete_module]
                              }
                              alt="badges"
                            ></img>
                          </div>
                          <div className="points-container">
                            <p>{user?.userInfo?.total_points} Points</p>
                          </div>
                        </div>
                      </div>
                      <div className=" col-12 col-xl-8">
                        <div className="current-badge all-badges">
                          <p className="component-heading-style">
                            Your Earned Badges
                          </p>
                          <div className="bages">
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 1
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge1.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 2
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge2.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 3
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge3.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 4
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge4.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 5
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge5.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 6
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge6.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 7
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge7.png`}
                              alt="badges"
                            ></img>
                            <img
                              className={`image-style ${badge?.userInfo?.complete_module >= 8
                                  ? null
                                  : "image-style-blur"
                                }`}
                              src={`${DOMAIN_NAME}images/Badges/badge8.png`}
                              alt="badges"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="badge-info-container">
                      <p className="component-heading-style">
                        How to Earn Points and Badges
                      </p>
                      {/* <p className="description">HoLorem ipsum dolor sit amet consectetur. Eget mi in.</p> */}
                      <div className="info-container">
                        <div className="points-info">
                          <p>25 Points</p>
                          <p>Each time you log into iBroadway</p>
                        </div>
                        <div className="points-info">
                          <p>25 Points</p>
                          <p>When you upload your avatar image</p>
                        </div>
                        <div className="points-info">
                          <p>50 Points</p>
                          <p>Each time you complete a questionnaire</p>
                        </div>
                        <div className="points-info">
                          <p>150 Points</p>
                          <p>Every time you complete a module</p>
                        </div>
                        <div className="points-info">
                          <p>A Badge</p>
                          <p>For each & every Module you complete</p>
                        </div>
                        <div className="points-info">
                          <p>Comic & Video</p>
                          <p>
                            Collect 7 Badges to unlock your Superhero comic Book
                            and Superhero Movie at the end of the program
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBadge;
