import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { DOMAIN_NAME, GifDomain_Name } from "../../../../env/Baseurl";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import GifPlayer from "react-gif-player";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import LastModuleBadge from "../../../../components/global/LastModulebadge";
import Sidebar from "../../../global/Sidebar";
import celebration from "../../../../assets/GIf/Celebration.gif";
import getBadgeInfo from "../../../../actions/badgeInfo";
import { handleModuleComplete } from "../../../../services/helpers";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const PartComplete = () => {
  /*************************************States*************************** */
  const [updateModule, setUpdateModule] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [gifStillImage1, setGifStillImage1] = useState("");
  const [gifImage1, setGifImage1] = useState("");
  const [gifStillImage2, setGifStillImage2] = useState("");
  const [gifImage2, setGifImage2] = useState("");
  const [gifStillImage3, setGifStillImage3] = useState("");
  const [gifImage3, setGifImage3] = useState("");
  /***********************hooks & LocalStoarge***********************/
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*******   Page Params  *******/
  const title = "Module 4 Completed";

  const badgeContent = (
    <div>
      {" "}
      <p className="greetingMessage1">Awesome Job!</p>
      <p className="greetingMessage1">
        {" "}
        Keep Going to claim the
        <span className="greetingMessage3"> Ultimate Reward </span>at the end of
        the Program{" "}
      </p>
    </div>
  );

  const content =
    "<div>Well done! You have finished the first component of the Online iGlebe Program. Now that you have completed these exercises you have hopefully come to understand the power that thoughts have over feelings. Now it's time to focus on the thoughts that trouble you. We are going to look closely at the negative thoughts that you selected in the opening questionnaire of this computer program.</div>";

  /*******   Helper Functions  *******/

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/essay", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate("/part_4/continue", { replace: true });
  };

  /***************************** module complete status  *******************/

  const handleModule = async () => {
    const dataObject = {
      user_id: user?.userInfo?._id,
      module_name: "part4",
    };
    let response = await handleModuleComplete(dataObject);
    // console.log(response?.userInfo?.total_points, 'its api response')
    const updatePoins = {
      ...user,
      userInfo: {
        ...user.userInfo,
        total_points: response?.userInfo?.total_points,
      },
    };
    localStorage.setItem("user", JSON.stringify(updatePoins));
    // console.log(updatePoins, "new points")
    setUpdateModule(true);
  };

  /**********************************UseEffect *************************** */
  useEffect(() => {
    handleModule();
    setGifStillImage1(
      {
        superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
        superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
        superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
        superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
        superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
        superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
      }[user?.userInfo?.superherosect]
    );
    setGifStillImage2(
      {
        superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
        superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
        superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
        superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
        superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
        superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
      }[user?.userInfo?.superherosect]
    );
    setGifStillImage3(
      {
        superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success3Still.png`,
        superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success3Still.png`,
        superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success3Still.png`,
        superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success3Still.png`,
        superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success3Still.png`,
        superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success3Still.png`,
      }[user?.userInfo?.superherosect]
    );
  }, []);

  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => { })
      .catch(() => { });
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  const handleGifImages = (gifUrl) => {
    if (
      {
        superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success1.gif`,
        superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success1.gif`,
        superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success1.gif`,
        superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success1.gif`,
        superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success1.gif`,
        superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success1.gif`,
      }[user?.userInfo?.superherosect].includes(gifUrl)
    ) {
      setGifStillImage1(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage1("");
    } else if (
      {
        superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success2.gif`,
        superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success2.gif`,
        superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success2.gif`,
        superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success2.gif`,
        superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success2.gif`,
        superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success2.gif`,
      }[user?.userInfo?.superherosect].includes(gifUrl)
    ) {
      setGifStillImage2(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage2("");
    } else {
      setGifStillImage3(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success3Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success3Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success3Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success3Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success3Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success3Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage3("");
    }
  };
  const handleImages = (imageUrl) => {
    if (
      {
        superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
        superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
        superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
        superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
        superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
        superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
      }[user?.userInfo?.superherosect].includes(imageUrl)
    ) {
      setGifImage1(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success1.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success1.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success1.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success1.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success1.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success1.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage1("");
      setGifImage2("");
      setGifStillImage2(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage3(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success3Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success3Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success3Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success3Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success3Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success3Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage3("");
    } else if (
      {
        superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
        superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
        superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
        superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
        superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
        superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
      }[user?.userInfo?.superherosect].includes(imageUrl)
    ) {
      setGifImage2(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success2.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success2.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success2.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success2.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success2.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success2.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage2("");
      setGifImage1("");
      setGifStillImage1(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage3(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success3Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success3Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success3Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success3Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success3Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success3Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage3("");
    } else {
      setGifImage3(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success3.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success3.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success3.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success3.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success3.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success3.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage3("");
      setGifImage2("");
      setGifStillImage2(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage1("");
      setGifStillImage1(
        {
          superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
          superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
          superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
          superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
          superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
          superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
        }[user?.userInfo?.superherosect]
      );
    }
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Part 4 Complete! | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style">{title}</div>
                  <div className="card-body p-0 card-body-main-container">
                    <div className="main-visual-container  main-visual-container-1 fourth-module-visual-container fourth-main-visual-container-1">
                      <div className="visuals-container">
                        <img
                          src={celebration}
                          alt="backgroud-visuals"
                          className="part-complete-image"
                        ></img>
                        <div className="textOver-graphics">
                          <p className="text1">YOU DID IT!!!</p>
                          <p className="text2">MODULE 4 COMPLETE</p>
                        </div>
                        <div className="visual-container-footer">
                          <p className="visual-container-footer-lower-text">
                            Here’s how your Superhero{" "}
                            <span className="normal-text-black-complete">
                              {" "}
                              Super{user?.userInfo?.superherosect.slice(5)}
                            </span>{" "}
                            Triumphed over their problems and emerged victorious
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="outerMost-visual-container">
                      <div className="main-visual-container  main-visual-container-2 fourth-module-visual-container-1">
                        {gifImage1 && (
                          <img
                            src={gifImage1}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success1.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success1.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success1.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success1.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success1.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success1.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            width="97%"
                            style={{ cursor: "pointer" }}
                            className="part4-images"
                          />
                        )}
                        {gifStillImage1 && (
                          <>
                            <img
                              src={gifStillImage1}
                              width="97%"
                              style={{ cursor: "pointer" }}
                              className="part4-images"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success1Still.png`,
                                    superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success1Still.png`,
                                    superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success1Still.png`,
                                    superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success1Still.png`,
                                    superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success1Still.png`,
                                    superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success1Still.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                      <div className="main-visual-container  main-visual-container-3 fourth-module-visual-container-1">
                        {gifImage2 && (
                          <img
                            src={gifImage2}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success2.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success2.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success2.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success2.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success2.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success2.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            width="97%"
                            style={{ cursor: "pointer" }}
                            className="part4-images"
                          />
                        )}
                        {gifStillImage2 && (
                          <>
                            <img
                              src={gifStillImage2}
                              width="97%"
                              style={{ cursor: "pointer" }}
                              className="part4-images"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success2Still.png`,
                                    superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success2Still.png`,
                                    superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success2Still.png`,
                                    superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success2Still.png`,
                                    superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success2Still.png`,
                                    superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success2Still.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                      <div
                        className="main-visual-container  main-visual-container-4 fourth-module-visual-container-1"
                        style={{ position: "relative" }}
                      >
                        {gifImage3 && (
                          <img
                            src={gifImage3}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs4/superStrong/success3.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs4/superResilient/success3.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs4/superCalm/success3.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs4/superBrave/success3.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs4/superFearless/success3.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs4/superSmart/success3.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            width="97%"
                            style={{ cursor: "pointer" }}
                            className="part4-images"
                          />
                        )}
                        {gifStillImage3 && (
                          <>
                            <img
                              src={gifStillImage3}
                              width="97%"
                              style={{ cursor: "pointer" }}
                              className="part4-images"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superStrong/success3Still.png`,
                                    superResilient: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superResilient/success3Still.png`,
                                    superCalm: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superCalm/success3Still.png`,
                                    superBrave: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superBrave/success3Still.png`,
                                    superFearless: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superFearless/success3Still.png`,
                                    superSmart: `${DOMAIN_NAME}images/part4/superHeroVisualGifs/superSmart/success3Still.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="main-comicContainer">
                      <LastModuleBadge
                        currentBadge={`${DOMAIN_NAME}images/Badges/badge4.png`}
                        nextBadge={`${DOMAIN_NAME}images/Badges/badge5.png`}
                        content={badgeContent}
                        progressBarData={50}
                        trophy={`${DOMAIN_NAME}images/badge_gif/Badge_6.gif`}
                        totalPoints={user?.userInfo?.total_points}
                      />
                    </div>
                    <div className="congratulation-box">
                      <div className="">
                        <p className="page-title-style">END MODULE 4</p>
                        <p className="mb-0">
                          Congratulations{" "}
                          <span className="normal-text-black">
                            {user.userInfo.Fname + " " + user.userInfo.Lname}
                          </span>{" "}
                          on completing Module 4.
                        </p>
                        <p>Here’s something cool to check out.</p>
                        {/* <div className='img-how-to-sty centre-align'>
                                                    <img src={`${DOMAIN_NAME}images/part5/complete/225.png`} alt="welcome-to-part-5" width='500px' />
                                                </div> */}
                        <div className="main-container my-5">
                          <div className=" sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${DOMAIN_NAME}images/part4/complete/complete1.jpg`}
                                alt="complete"
                              />
                            </div>
                          </div>
                        </div>
                        <p className="normal-text-black">
                          Sit down and lift your right foot, moving it in
                          clockwise circles. Simultaneously, lift your right
                          hand and move it in anti-clockwise circles in the air.
                          Your foot will change direction, and you won't be able
                          to control it.
                        </p>
                        <p className="mb-0">
                          This happens because the left half of the brain
                          controls the right half of the body, and the right
                          half of the brain controls the left half of the body.
                          However, the brain finds it extremely difficult to
                          have one half of the body perform two different
                          actions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous col-5 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-7 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartComplete;
