import "../../../../styles/dashboard-style.css";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7firstpage/part7firstPage.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part7FirstPage = () => {
  const moduleName = localStorage.setItem("moduleName", "Part 7");
  const part1Accessed = localStorage.setItem("part7Accessed", "true");
  const [isPlaying, setIsPlaying] = useState(true);

  /*******  Page Params  *******/
  const title = "Module 7: My Defence";
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_7/preventing_relapse_2`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/complete`, { replace: true });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };
  /******  Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>My Defence | ASRC iBroadway</title>
      </Helmet>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style  m-0">{title}</div>
                  <div className="card-body">
                    <div>
                      <p>
                        Everybody has GOOD and BAD days, and you should expect
                        your progress to be ‘up and down’ when dealing with
                        social anxiety. Many people fall into the trap of
                        getting this wrong, which can make them give up.
                      </p>
                    </div>
                    <div className="main-container">
                      <div className="container">
                        <p className="component-heading-style">
                          THE 'IMPROVEMENT' MYTH
                        </p>
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part7/part7firstPage/278.jpg`}
                            alt="myth"
                          />
                        </div>
                        <p className="subtitle-style text-center">
                          Many people think this is how improvement over time
                          for social anxiety shout look like.
                        </p>
                      </div>
                      <div className="container">
                        <p className="component-heading-style">
                          THE REALITY OF 'IMPROVEMENT'
                        </p>
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part7/part7firstPage/276.jpg`}
                            alt="reality"
                          />
                        </div>
                        <p className="subtitle-style text-center">
                          This is what improvement over time for social anxiety
                          really looks like.
                        </p>
                      </div>
                    </div>

                    <div className="main-container mb-5">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${DOMAIN_NAME}images/part7/part7firstPage/277.png`}
                            alt="improvement"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer isPlaying={isPlaying}
                setIsPlaying={setIsPlaying} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part7FirstPage;
