import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7thirdpage/part7thirdpage.css";
import "../../../../styles/part_6/part6secondpage/part6secondpage.css";
import "../../../../styles/part_6/page6thirdpage/page6thirdpage.css";
import "../../../../styles/accordian/accordian.css";

import { Button, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BASE_URL } from "../../../../env/Baseurl";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAvoidanceQuestions } from "../../../../services/part6/get-avoidance-questions";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import axios from "axios";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useNavigate } from "react-router-dom";

const Part6ThirdPage = () => {
  const [avoidanceQuestions, errorMessage] = GetAvoidanceQuestions(); // get Avoidance Questions from API
  const [isHovering, setIsHovering] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   States *******/
  const [state, setState] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [apiErrorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [buttonState, setButtonState] = useState(true);

  /*******   params  *******/
  const title1 = "My 2nd Listening Exercise";
  const title2 = "AN EXAMPLE OF ROAM & ZONE";
  const title3 = "MY ROAM & ZONE EXPERIMENT";

  /*******  Helper Functions and variables  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (state.some((item) => item.question_id === name)) {
      setState(state.filter((state) => state.question_id !== name));
    } else {
      setState([...state, { question_id: name }]);
    }

    if (state.length === 0) {
      setDisabled(true);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state,
      });
      const response = await axios.post(
        BASE_URL + `sra_fill_avoidance/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.length === 3) {
        handleAudioStoreData();
        navigate("/part_6/sar_responses", { replace: true });
      } else {
        alert("Please select atleast one of the options.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_6/sar_responses", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/att`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);


  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };

  /*******  Hooks  *******/

  useEffect(() => {
    if (state.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };

  /*******  Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            Situational Attentional Refocusing (SAR) | ASRC iBroadway
          </title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      s
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <p className="page-title-style m-0">{title1}</p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <div className="row example-container-reverse mt-0 zone-home-technique">
                            <div className="text-box">
                              <p>
                                ‘The Zone @ home technique builds your mind's
                                fitness, which is why it was practiced alone. Once
                                mastered, you can move on to using this next Mind
                                Control technique – Roam & Zone<span className="normal-text-black font-italic"> (Roam & Zone is SAR
                                  (situational attentional refocusing) taken from
                                  Adrian Well’s book, Emotional Disorders and
                                  Metacognition: Innovative Cognitive Therapy).</span>
                              </p>
                            </div>
                            <div className="image-box" onClick={() => setIsHovering(!isHovering)} style={{ cursor: 'pointer' }}>
                              <div className="main-container">
                                <div className="sub-container center-align">
                                  <div className="image-container">
                                    <img
                                      src={`${DOMAIN_NAME}images/part6/page6thirdPage/Roam&Zone.jpg`}
                                      alt="Stress Release tips"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isHovering && (
                            <p className=" text-center">
                              (Roam & Zone is SAR (situational attentional
                              refocusing) taken from Adrian Well’s book,
                              Emotional Disorders and Metacognition:
                              Innovative Cognitive Therapy.)
                            </p>
                          )}

                          {/* <p>
                            ‘The Zone @ home technique builds your mind's
                            fitness, which is why it was practiced alone. Once
                            mastered, you can move on to using this next Mind
                            Control technique – Roam & Zone<span className="font-italic "> (Roam & Zone is SAR
                              (situational attentional refocusing) taken from
                              Adrian Well’s book, Emotional Disorders and
                              Metacognition: Innovative Cognitive Therapy).</span>
                          </p>
                          <div className="main-container my-5" onClick={() => setIsHovering(!isHovering)} >
                            <div className=" sub-container center-align" style={{ maxWidth: '450px', cursor: 'pointer' }} >
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/Roam&Zone.jpg`}
                                  alt="Stress Release tips"
                                />
                              </div>
                            </div>
                          </div>
                          {isHovering && (
                            <p className=" text-center">
                              (Roam & Zone is SAR (situational attentional
                              refocusing) taken from Adrian Well’s book,
                              Emotional Disorders and Metacognition:
                              Innovative Cognitive Therapy.)
                            </p>
                          )} */}
                          <p className="component-heading-style mt-5">
                            What is Roam & Zone?
                          </p>
                          <p>
                            Roam & Zone is another psychological
                            listening-technique for using in social situations
                            where you’ll be refocusing your attention away from
                            negative feedback and instead focus on things around
                            you to lower anxiety.
                          </p>
                          <p>Let’s explain with an example:</p>
                          <p>
                            Kirsten was talking with a group of friends. She
                            told a joke and one friend rolled her eyes and shook
                            her head, but everyone else in the group laughed
                            along. Kirsten’s focus caused her to only see her
                            friend’s disapproving response, but not all her
                            other friends’ support and approval of her joke.
                          </p>
                          <div className="main-container my-5 ">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/255.jpg`}
                                  alt="Stress Release tips"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            Kirsten was able to use Zoning and refocus her
                            attention away from her friend’s negative feedback
                            and instead onto other aspects of the environment
                            that were positive and helpful, such as the other
                            people in the group.
                          </p>
                          <div className="main-container my-5 ">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/256.jpg`}
                                  alt="Stress Release tips"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="main-container my-3">
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/257.jpg`}
                                  alt="situation"
                                />
                              </div>
                            </div>
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/258.png`}
                                  alt="situation"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="tip-container">
                            <div className="tip-image-container">
                              <img
                                className="part6_bar_image"
                                src={tipBackground}
                                alt="tip-background"
                                width="923px"
                              />
                            </div>
                            <div className="idea">
                              <img
                                className="part6_bar_image"
                                src={idea}
                                alt="tip"
                              />
                            </div>
                            <div className="tip-content">
                              <span className="whiteText">
                                On the next page we’ll see a person put{" "}
                                <span className="yellowText">ROAM & ZONE</span>{" "}
                                into practice.
                              </span>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={currentAudio?.enabledAudio < 1 ? true : false}
                    onClick={() => {
                      if (currentAudio?.enabledAudio < 1) return false;
                      else {
                        setIsPlaying(true);
                        setCurrentAudio({ ...currentAudio, audio: 1 });
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <p className="page-title-style m-0">{title2}</p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>Here is Wendy’s story:</p>
                          <div className="main-container my-5 ">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/260.jpg`}
                                  alt="Stress Release tips"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="main-container my-3">
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/261.jpg`}
                                  alt="situation"
                                />
                              </div>
                            </div>
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/262.jpg`}
                                  alt="situation"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="main-container my-5 ">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${DOMAIN_NAME}images/part6/page6thirdPage/263.jpg`}
                                  alt="Stress Release tips"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={currentAudio?.enabledAudio < 2 ? true : false}
                    onClick={() => {
                      if (currentAudio?.enabledAudio < 2) return false;
                      else {
                        setIsPlaying(true);
                        setCurrentAudio({ ...currentAudio, audio: 2 });
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <p className="page-title-style m-0">{title3}</p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>
                            We want you to practise this technique in situations
                            you find challenging. Below is a list of situations
                            that you previously told us were difficult for you.
                            Choose 3 of the situations in order to practice ROAM
                            & ZONE.
                          </p>
                          {errorMessage ? (
                            <div className="container mt-5">
                              <div className="row justify-content-center">
                                <div
                                  className="alert alert-danger text-center"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              </div>
                            </div>
                          ) : avoidanceQuestions !== undefined &&
                            avoidanceQuestions.length !== 0 ? (
                            <div className="content-wrap">
                              {avoidanceQuestions.avoidance_data.map(
                                (item, index) => {
                                  const card = (
                                    <div
                                      className="left-content checkbx-sty listing"
                                      key={index}
                                    >
                                      <InputGroup.Checkbox
                                        defaultChecked={item.checkbox_status}
                                        disabled={item.disable_status}
                                        aria-label="checkbox 1"
                                        value={item.question}
                                        name={item._id}
                                        selected={
                                          Boolean(state[item.question]) &&
                                          state[item.question] === item.question
                                        }
                                        onChange={handleChange}
                                      />
                                      {item.question}
                                    </div>
                                  );
                                  return card;
                                }
                              )}
                            </div>
                          ) : (
                            <div className="loader-icon">
                              <TailSpin
                                color="#4f5962"
                                height={50}
                                width={50}
                              />
                            </div>
                          )}

                          <p>
                            <br />
                            <span className="">
                              Log-out and face these situations while using ROAM
                              & ZONE to focus on the positive aspects of the
                              environment. Once done, log back in and record
                              your experiences on the following page.
                            </span>{" "}
                            Good luck!
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {avoidanceQuestions !== undefined &&
                    avoidanceQuestions.length !== 0 &&
                    avoidanceQuestions.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? "Please select 3 situations you have been avoiding in the past and that you think you can use to practise SAR."
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={audio?.audioData?.length > 0 ? buttonState : false}
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{apiErrorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part6ThirdPage;
