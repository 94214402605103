import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { DOMAIN_NAME, GifDomain_Name } from "../../../../env/Baseurl";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import GifPlayer from "react-gif-player";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import LastModuleBadge from "../../../../components/global/LastModulebadge";
import Sidebar from "../../../global/Sidebar";
import celebration from "../../../../assets/GIf/Celebration.gif";
import getBadgeInfo from "../../../../actions/badgeInfo";
import { handleModuleComplete } from "../../../../services/helpers";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const PartComplete = () => {
  /*******************************States *******************************/
  const [isPlaying, setIsPlaying] = useState(true);
  const [gifStillImage1, setGifStillImage1] = useState("");
  const [gifImage1, setGifImage1] = useState("");
  const [gifStillImage2, setGifStillImage2] = useState("");
  const [gifImage2, setGifImage2] = useState("");
  /*************************hooks & lodalstorage***************************/
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*******   Page Params  *******/
  const title = "Module 3 Completed";

  const badgeContent = (
    <div>
      {" "}
      <p className="greetingMessage1">Awesome Job!</p>
      <p className="greetingMessage1">
        {" "}
        Keep Going to claim the
        <span className="greetingMessage3"> Ultimate Reward </span>at the end of
        the Program{" "}
      </p>
    </div>
  );

  /*******   Helper Functions  *******/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/intro", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate("/part_3/your_model_of_social_anxiety", { replace: true });
  };

  /****************** module complete status  *******************/

  const handleModule = async () => {
    const dataObject = {
      user_id: user?.userInfo?._id,
      module_name: "part3",
    };
    let response = await handleModuleComplete(dataObject);
    const updatePoins = {
      ...user,
      userInfo: {
        ...user.userInfo,
        total_points: response?.userInfo?.total_points,
      },
    };
    localStorage.setItem("user", JSON.stringify(updatePoins));
  };

  /*******************************UseEffect**********************/

  useEffect(() => {
    handleModule();
    setGifStillImage1(
      {
        superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/safetyStill.png`,
        superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/safetyStill.png`,
        superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/safetyStill.png`,
        superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/safetyStill.png`,
        superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/safetyStill.png`,
        superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/safetyStill.png`,
      }[user?.userInfo?.superherosect]
    );
    setGifStillImage2(
      {
        superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/fearStill.png`,
        superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/fearStill.png`,
        superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/fearStill.png`,
        superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/fearStill.png`,
        superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/fearStill.png`,
        superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/fearStill.png`,
      }[user?.userInfo?.superherosect]
    );
  }, []);

  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => { })
      .catch(() => { });
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  const handleGifImages = (gifUrl) => {
    if (
      {
        superStrong: `${GifDomain_Name}/superHeroVisualGifs3/superStrong/safety.gif`,
        superResilient: `${GifDomain_Name}/superHeroVisualGifs3/superResilient/safety.gif`,
        superCalm: `${GifDomain_Name}/superHeroVisualGifs3/superCalm/safety.gif`,
        superBrave: `${GifDomain_Name}/superHeroVisualGifs3/superBrave/safety.gif`,
        superFearless: `${GifDomain_Name}/superHeroVisualGifs3/superFearless/safety.gif`,
        superSmart: `${GifDomain_Name}/superHeroVisualGifs3/superSmart/safety.gif`,
      }[user?.userInfo?.superherosect].includes(gifUrl)
    ) {
      setGifStillImage1(
        {
          superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/safetyStill.png`,
          superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/safetyStill.png`,
          superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/safetyStill.png`,
          superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/safetyStill.png`,
          superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/safetyStill.png`,
          superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/safetyStill.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage1("");
    } else {
      setGifStillImage2(
        {
          superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/fearStill.png`,
          superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/fearStill.png`,
          superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/fearStill.png`,
          superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/fearStill.png`,
          superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/fearStill.png`,
          superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/fearStill.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage2("");
    }
  };
  const handleImages = (imageUrl) => {
    if (
      {
        superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/safetyStill.png`,
        superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/safetyStill.png`,
        superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/safetyStill.png`,
        superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/safetyStill.png`,
        superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/safetyStill.png`,
        superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/safetyStill.png`,
      }[user?.userInfo?.superherosect].includes(imageUrl)
    ) {
      setGifImage1(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs3/superStrong/safety.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs3/superResilient/safety.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs3/superCalm/safety.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs3/superBrave/safety.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs3/superFearless/safety.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs3/superSmart/safety.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage1("");
      setGifImage2("");
      setGifStillImage2(
        {
          superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/fearStill.png`,
          superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/fearStill.png`,
          superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/fearStill.png`,
          superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/fearStill.png`,
          superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/fearStill.png`,
          superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/fearStill.png`,
        }[user?.userInfo?.superherosect]
      );
    } else {
      setGifImage2(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs3/superStrong/fear.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs3/superResilient/fear.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs3/superCalm/fear.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs3/superBrave/fear.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs3/superFearless/fear.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs3/superSmart/fear.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage2("");
      setGifImage1("");
      setGifStillImage1(
        {
          superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/safetyStill.png`,
          superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/safetyStill.png`,
          superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/safetyStill.png`,
          superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/safetyStill.png`,
          superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/safetyStill.png`,
          superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/safetyStill.png`,
        }[user?.userInfo?.superherosect]
      );
    }
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Part 3 Complete! | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-0">{title}</div>
                  <div className="card-body p-0 card-body-main-container">
                    <div className="main-visual-container  main-visual-container-1">
                      <div className="visuals-container">
                        <img
                          src={celebration}
                          alt="backgroud-visuals"
                          className="part-complete-image"
                        ></img>
                        <div className="textOver-graphics">
                          <p className="text1">YOU DID IT!!!</p>
                          <p className="text2">MODULE 3 COMPLETE</p>
                        </div>
                        <div className="visual-container-footer">
                          <p className="visual-container-footer-lower-text">
                            Here’s how your Superhero{" "}
                            <span className="normal-text-black-complete">
                              {" "}
                              Super{user?.userInfo?.superherosect.slice(5)}
                            </span>{" "}
                            Triumphed over their problems and emerged victorious
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="outerMost-visual-container">
                      <div className="main-visual-container main-visual-container-2">
                        {/* <div className='d-flex justify-content-center' > */}
                        {gifImage1 && (
                          <img
                            src={gifImage1}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs3/superStrong/safety.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs3/superResilient/safety.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs3/superCalm/safety.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs3/superBrave/safety.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs3/superFearless/safety.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs3/superSmart/safety.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            className="visual-container-superhero"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {gifStillImage1 && (
                          <>
                            <img
                              src={gifStillImage1}
                              className="visual-container-superhero"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/safetyStill.png`,
                                    superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/safetyStill.png`,
                                    superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/safetyStill.png`,
                                    superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/safetyStill.png`,
                                    superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/safetyStill.png`,
                                    superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/safetyStill.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="main-visual-container main-visual-container-3">
                        {gifImage2 && (
                          <img
                            src={gifImage2}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs3/superStrong/fear.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs3/superResilient/fear.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs3/superCalm/fear.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs3/superBrave/fear.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs3/superFearless/fear.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs3/superSmart/fear.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            className="visual-container-superhero"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {gifStillImage2 && (
                          <>
                            <img
                              src={gifStillImage2}
                              className="visual-container-superhero"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superStrong/fearStill.png`,
                                    superResilient: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superResilient/fearStill.png`,
                                    superCalm: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superCalm/fearStill.png`,
                                    superBrave: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superBrave/fearStill.png`,
                                    superFearless: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superFearless/fearStill.png`,
                                    superSmart: `${DOMAIN_NAME}images/part3/superHeroVisualGifs/superSmart/fearStill.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="main-comicContainer">
                      <LastModuleBadge
                        currentBadge={`${DOMAIN_NAME}images/Badges/badge3.png`}
                        nextBadge={`${DOMAIN_NAME}images/Badges/badge4.png`}
                        content={badgeContent}
                        progressBarData={37.5}
                        trophy={`${DOMAIN_NAME}images/badge_gif/Badge_5.gif`}
                        totalPoints={user?.userInfo?.total_points}
                      />
                    </div>
                    <div className="congratulation-box">
                      <div className="">
                        <p className="page-title-style">END MODULE 3</p>
                        <p className="mb-0">
                          Well done{" "}
                          <span className="normal-text-black">
                            {user.userInfo.Fname + " " + user.userInfo.Lname}
                          </span>{" "}
                          on completing the 3rd module.
                        </p>
                        <p className="mb-0">Here’s some more useless stuff.</p>
                        <p>Count the “F’s” that are in the following text:</p>
                        <p className="page-title-style pt-3">
                          FINISHED FILES ARE THE RE SULT OF YEARS OF SCIENTI FIC
                          STUDY COMBINED WITH THE EXPERIENCE OF YEARS.
                        </p>
                        <p className="mb-0">How many F’s did you find?</p>
                        <p className="">
                          Wrong:
                          <span className="small-text">
                            {" "}
                            There are 6 F’s in the text above. Read and try
                            again.
                          </span>
                          .
                        </p>
                        <p className="mb-0">How is this possible?</p>
                        <p className="small-text">
                          Our brains often don’t worry about processing the word{" "}
                          <span className=""> OF</span>. If you found 3 you’re
                          normal, 4 is excellent, and all six F’s then you’re a
                          genius.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous col-5 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-7 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartComplete;
