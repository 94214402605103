import "../../../../styles/adminlte.min.css";
import "../../../../styles/part1/thinkingExercise/thinkingExercise.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import axios from "axios";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useNavigate } from "react-router-dom";

const ThinkingExercise1 = () => {
  const [inputQuestions, errorMessage] = GetInputQuestions(1);

  /*******   States *******/
  const [isPlaying, setIsPlaying] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageShow, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  /********constant*********/
  const type = "create_Excercise1";
  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/; // Regex to check for only special characters in textbox
  const title1 = "Example Thought Exercise";
  const title2 = "'Thought' Exercise 1";
  const images = ["45.jpg", "48.jpg", "46.jpg", "47.jpg"];

  /**************hooks***********/
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);
  /********functions**********/
  // save as draft modal
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);
  // error modal
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;
    if (state.data.some((item) => item.thinkingExcercise_id === inputId)) {
      // Checking Input ID in state on change
      let updatedData = state.data.map((item) => {
        if (item.thinkingExcercise_id === inputId) {
          return {
            ...item,
            thinkingExcercise_id: inputId,
            userResponce: value,
          }; // Updating user response in state
        }
        return item;
      });
      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { thinkingExcercise_id: inputId, userResponce: value },
        ], // Adding new user response in state
      }));
    }

    if (value !== "" || value === "") {
      // Setting Tooltip message for empty response or regex not matched
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage:
              "Please submit all the challenge responses to continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.data.length >= inputQuestions.count) {
        navigate(`/part_1/lets_review`, { replace: true });
      } else {
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 1 Input forms Api");
        ErrorLogging("HTTP POST Part 1 /_type/userId" + error);
        handle();
      }
    }
  };

  // SAVE AS DRAFT
  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Please check Part 1 Input forms Api");
        ErrorLogging("HTTP POST Part 1 /_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/lets_review`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/waiting_for_the_bus`, { replace: true });
  };

  // close modal on its own
  setTimeout(handleClose, 1000);

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  /*******   useEffects  *******/
  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.userResponce === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.userResponce))
    );
    if (
      state.data.length >= inputQuestions?.count &&
      arr.length === 0 &&
      arr1.length === 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state.data]);

  useEffect(() => {
    if (inputQuestions?.count === 0) {
      setDisabled(false);
    }
  }, [inputQuestions]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Page Params  *******/

  /******useEffect***** */
  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);
  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>'Thought' Exercise 1 | ASRC iBroadway</title>
      </Helmet>
      <>
        <div id="printAble"></div>
        <div id="mainContainer">
          <div>
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div className="row content-with-sidebar-row m-0">
            <div className="col-md-2  position-static pl-0">
              <div className="leftside">
                <Sidebar />
              </div>
            </div>
            <div className="col-md-10 content-widthsidebar content-wrapper">
              <div className="card">
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <div>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-heading"
                        >
                          <Typography>
                            <div className="page-title-style m-0">{title1}</div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="card-body p-0">
                              <p className="mt-0">
                                Look carefully at the example answers for the 4
                                different ways of thinking about the situation
                                below because you’re about to have a go yourself
                                on the next page.{" "}
                              </p>
                              <div className="situation-box">
                                <p className="component-sub-heading-style">
                                  Situation:
                                </p>
                                <p className="normal-text-black mb-0">
                                  Goran was walking down the street when a dog,
                                  not on a lead and without an owner, limped
                                  towards him. The dog had blood on its right
                                  front leg.
                                </p>
                              </div>
                              <div className="main-container my-5">
                                <div className=" sub-container center-align">
                                  <div className="image-container">
                                    <img
                                      src={`${DOMAIN_NAME}images/part1/exercise1/44.jpg`}
                                      alt="hannah"
                                    />
                                  </div>
                                </div>
                              </div>
                              <p>
                                Goran’s feelings will depend not on the dog
                                itself, but on the way he thinks in this
                                situation. Below we have listed 4 different
                                thoughts that he might have. If Goran has
                                anxious thoughts, he will get anxious. If Goran
                                has angry thoughts, he will get angry. If Goran
                                has sad thoughts, he will get sad. Finally, if
                                Goran has happy thoughts he will feel happy
                                (even though the dog is bleeding)! The cause of
                                Goran’s feelings (or emotions) will be his
                                thoughts.
                              </p>
                              <div className="part1-main-container my-5">
                                <div className="image-with-text">
                                  <div className="container">
                                    <img
                                      className="causes"
                                      src={`${DOMAIN_NAME}images/part1/exercise1/45.jpg`}
                                      alt="Stress Release tips"
                                    />
                                  </div>
                                  <p className="text-center">
                                    The dog is gonna bite me.
                                  </p>
                                </div>
                                <div className="image-with-text">
                                  <div className="container">
                                    <img
                                      className="causes"
                                      src={`${DOMAIN_NAME}images/part1/exercise1/46.jpg`}
                                      alt="Stress Release tips"
                                    />
                                  </div>
                                  <p className="text-center">
                                    The world is full of lonely people and
                                    lonely animals.
                                  </p>
                                </div>
                                <div className="image-with-text">
                                  <div className="container">
                                    <img
                                      className="causes"
                                      src={`${DOMAIN_NAME}images/part1/exercise1/47.jpg`}
                                      alt="Stress Release tips"
                                    />
                                  </div>
                                  <p className="text-center">
                                    I'm going to find the owner and return their
                                    lost dog. Maybe I'll get a reward.
                                  </p>
                                </div>
                                <div className="image-with-text">
                                  <div className="container">
                                    <img
                                      className="causes"
                                      src={`${DOMAIN_NAME}images/part1/exercise1/48.jpg`}
                                      alt="Stress Release tips"
                                    />
                                  </div>
                                  <p className="text-center">
                                    The owner SHOULD have their dog on a lead.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        disabled={currentAudio?.enabledAudio < 1 ? true : false}
                        onClick={() => {
                          if (currentAudio?.enabledAudio < 1) return false;
                          else {
                            setIsPlaying(true);
                            setCurrentAudio({ ...currentAudio, audio: 1 });
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-heading"
                        >
                          <Typography>
                            <div className="page-title-style m-0">{title2}</div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="card-body p-0">
                              <div className="">
                                <p>
                                  You have a go now. Remember, only write in
                                  ‘thoughts’ and don’t confuse them with
                                  feelings (emotions) or behaviours.{" "}
                                </p>
                                <div className="situation-box mb-3">
                                  <p className="component-sub-heading-style">
                                    Situation:
                                  </p>
                                  <p className="normal-text-black mb-0">
                                    Goran was walking down the street when a
                                    dog, not on a lead and without an owner,
                                    walked toward him. The dog had blood on its
                                    right leg.
                                  </p>
                                  <p className="normal-text-black mb-0">
                                    Write down a thought that could make Goran
                                    feel:
                                  </p>
                                </div>
                              </div>
                              <div className="card-body part4-tenthPage thinking-exercise p-0">
                                <div className="">
                                  {errorMessage ? (
                                    <div className="container mt-5">
                                      <div className="row justify-content-center">
                                        <div
                                          className="alert alert-danger text-center"
                                          role="alert"
                                        >
                                          {errorMessage}
                                        </div>
                                      </div>
                                    </div>
                                  ) : inputQuestions !== undefined &&
                                    inputQuestions.length !== 0 ? (
                                    <div className="content-wrap">
                                      <div className="content-topheads-text">
                                        <div className="left-content">
                                          <div className="row">
                                            {inputQuestions?.data?.map(
                                              (item, index) => {
                                                const card = (
                                                  <div
                                                    className="col-xl-6 mb-4"
                                                    width="40%"
                                                    key={index}
                                                  >
                                                    <div className="question-container">
                                                      <p className="questions component-heading-style">
                                                        {index +
                                                          1 +
                                                          "." +
                                                          " " +
                                                          item?.question}
                                                      </p>
                                                    </div>
                                                    <div className="main-container my-5">
                                                      <div className=" sub-container center-align">
                                                        <div className="image-container">
                                                          <img
                                                            src={`${DOMAIN_NAME}images/part1/exercise1/${images[index]}`}
                                                            alt="Ben"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="textbox">
                                                      <textarea
                                                        readOnly={
                                                          item.disable_status
                                                        }
                                                        className="form-control"
                                                        id="ControlTextarea"
                                                        rows="2"
                                                        placeholder="Your Answer Here..."
                                                        defaultValue={
                                                          item.responce
                                                        }
                                                        onChange={handleChange(
                                                          item._id
                                                        )}
                                                      />
                                                    </div>
                                                    {textBoxValidation.id ===
                                                      item._id && (
                                                      <small className="text-danger">
                                                        {
                                                          textBoxValidation.errorMessage
                                                        }
                                                      </small>
                                                    )}
                                                  </div>
                                                );
                                                return card;
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="loader-icon">
                                      <TailSpin
                                        color="#4f5962"
                                        height={50}
                                        width={50}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="tip-container waiting-for-the-bus">
                                  <div className="tip-image-container">
                                    <img
                                      className="part6_bar_image"
                                      // style={{ maxWidth: '1050px' }}
                                      src={tipBackground}
                                      alt="tip-background"
                                      // width="923px"
                                      // max-width='1050px'
                                    />
                                  </div>
                                  <div className="idea">
                                    <img
                                      className="part6_bar_image"
                                      src={idea}
                                      alt="tip"
                                    />
                                  </div>
                                  <div className="tip-content">
                                    <span className="whiteText">
                                      "If it feels weird coming up with the
                                      answers, then stay with it. You’re making
                                      new neural pathways in your brain, the key
                                      to building confidence."
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    {inputQuestions !== undefined &&
                    inputQuestions.length !== 0 &&
                    inputQuestions.global_disable_status === false ? (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0 mr-1"
                          onClick={saveDraft}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {inputQuestions !== undefined &&
                    inputQuestions.length !== 0 &&
                    inputQuestions.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? textBoxValidation.disabledMessage
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={buttonState}
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              {successMessage ? (
                <Modal.Body>{successMessage}</Modal.Body>
              ) : (
                <Modal.Body>{errorMessageShow}</Modal.Body>
              )}
            </Modal>

            {/* error message modal */}

            <Modal show={showError}>
              <Modal.Body>{errorMessageShow}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="col-md-12 dashboard-footer">
              <Footer
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              />
            </div>
          </div>
        </div>
      </>
      {/* <InputForms
                title={title}
                content={content}
                questions={inputQuestions}
                link='part_1/lets_review'
                type={'create_Excercise1'}
                situation={situation}
                sitnum={1}
                images={images}
                errorMessage={errorMessage}
                previousLink='part_1/waiting_for_the_bus'
                tip={tip}
            /> */}
    </div>
  );
};

export default ThinkingExercise1;
