import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part1/begin/begin.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Begin = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const moduleName = localStorage.setItem("moduleName", "Part 1");
  const part1Accessed = localStorage.setItem("part1Accessed", "true");
  /*******   Page Params  *******/

  const title = `Okay ${user?.userInfo?.Fname}  ${user?.userInfo?.Lname} ! Let's begin your treatment.`;

  /*******functions************/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/waiting_for_the_bus`, { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/intro/dass_questionnaire`, { replace: true });
  };

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
  };

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Okey ! Let's Begin | ASRC iBroadway</title>
      </Helmet>
      <>
        <div id="printAble"></div>
        <div id="mainContainer" className="dashboardContent">
          <div>
            {/* <Header/> */}
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div className="row m-0">
            <div className="col-md-2  position-static pl-0">
              <div className="leftside">
                <Sidebar />
              </div>
            </div>
            <div className="col-md-10 content-widthsidebar content-wrapper">
              <div className="card">
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <div className="title page-title-style m-0">
                      Module 1: Building My Social Confidence
                    </div>
                    <div className="card-body">
                      <div>
                        <p className="component-heading-style">
                          What's social anxiety?
                        </p>
                        <p>
                          Social anxiety is when you worry about looking stupid
                          or embarrassing yourself in front of others. It may
                          even stop you from going to new places or doing things
                          with new people.
                        </p>
                        <p>What’s the big deal about social anxiety?</p>
                        <ul class="normal-text dark-purple-color">
                          <li>
                            More than 1 in 10 teenagers are affected by social
                            anxiety.
                          </li>
                          <li>
                            Even though social anxiety doesn't cause stuttering,
                            it can make it worse.
                          </li>
                          <li>
                            Social anxiety stops you from doing fun stuff.
                          </li>
                        </ul>
                        <p>Why treat your social anxiety with CBTpsych?</p>
                        <ul class="normal-text dark-purple-color">
                          <li>
                            You'll learn to be more confident talking to others.
                          </li>
                          <li>
                            You'll learn to apply this confidence in lots of
                            areas like: sport, dating, and class presentations.
                          </li>
                          <li>
                            We believe that life just feels better when you're
                            not anxious.
                          </li>
                        </ul>
                      </div>
                      <div className="situation-box">
                        <p className="component-heading-style">
                          Stuttering Myths
                        </p>
                        <p>
                          There are heaps of myths about stuttering. Here are a
                          few:
                        </p>
                        <div className="pt-3">
                          <p className="mb-0">
                            Myth
                            <span className="normal-text-black">
                              {" "}
                              - People who stutter are not smart.
                            </span>
                          </p>
                          <p>
                            Fact
                            <span className="normal-text-black">
                              {" "}
                              - Stuttering has nothing to do with how smart
                              someone is.
                            </span>
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className=" mb-0">
                            Myth
                            <span className="normal-text-black">
                              {" "}
                              - Stuttering is caused by bad parenting.
                            </span>
                          </p>
                          <p>
                            Fact
                            <span className="normal-text-black">
                              {" "}
                              - Stuttering isn’t caused by poor parenting – it
                              is more to do with how the brain is wired.
                            </span>
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className=" mb-0">
                            Myth
                            <span className="normal-text-black">
                              {" "}
                              - Listening to a parent or sibling who stutters
                              can teach a young child to stutter.
                            </span>
                          </p>
                          <p>
                            Fact
                            <span className="normal-text-black">
                              {" "}
                              - Listening to others who stutter does not cause
                              stuttering.
                            </span>
                          </p>
                        </div>
                        <div className="py-3">
                          <p className="mb-0">
                            Myth
                            <span className="normal-text-black">
                              {" "}
                              - Nothing can be done for stuttering.
                            </span>
                          </p>
                          <p className="mb-0">
                            Fact
                            <span className="normal-text-black">
                              {" "}
                              - Speech pathologists, psychologists and programs
                              like CBTpsych can help people reduce their
                              stuttering and change the way they think about
                              tricky speaking situations.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="component-heading-style mt-4">
                          What is CBT?
                        </p>
                        <p>
                          CBT stands for Cognitive Behavioural Therapy. It is a
                          counselling technique that is used by many clinical
                          psychologists to help you understand, manage and
                          change you thoughts (cognitions) and actions
                          (behaviour). CBT is cool for a whole range of reasons
                          (which you're going to learn). Some other people who use
                          CBT to manage their social or performance anxiety
                          include:
                        </p>
                        <div className="part1-main-container my-5 divider">
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/socialConfidence/29.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p className="component-heading-style ">
                              Musicians
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/socialConfidence/30.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>

                            <p className="component-heading-style ">Actors</p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/socialConfidence/31.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>

                            <p className="component-heading-style ">Athletes</p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/socialConfidence/32.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p className="component-heading-style ">
                              Celebrities
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 mb-0">
                    <div className="title page-title-style m-0">
                      Your Brain is Plastic
                    </div>
                    <div className="card-body brain-plastic-exercise">
                      <div>
                        <p className="component-sub-heading-style">Exercise</p>
                        <p className="component-heading-style">Try this:</p>
                        <div className="main-container my-5">
                          <div className="container">
                            <p className="">
                              1. Cross your arms. How did it feel?
                            </p>
                            <div className="image-container plastic-brain">
                              <img
                                src={`${DOMAIN_NAME}images/part1/plasticBrain/34.jpg`}
                                alt="image"
                              />
                            </div>
                          </div>
                          <div className="container">
                            <p className="">
                              2. Cross your arms again but this time swap them
                              around with the bottom arm on top. How did this
                              feel?
                            </p>
                            <div className="image-container plastic-brain">
                              <img
                                src={`${DOMAIN_NAME}images/part1/plasticBrain/35.jpg`}
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                        <p className="">
                          The first attempt would have felt quite normal while
                          the second attempt probably felt quite weird. Why?
                          Because you have only developed neural pathways in
                          your brain to cross your arms the first way and not
                          the second.
                          <span
                            className="normal-text-black"
                            style={{ color: "var(--purple)" }}
                          >
                            {" "}
                            But with practice these neural pathways can be
                            changed.
                          </span>
                        </p>
                        <div className="row example-container divider">
                          <div className="image-box">
                            <div className="main-container">
                              <div className="sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part1/plasticBrain/36.jpg`}
                                    alt="hannah crossing street"
                                    width=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-box">
                            <p className="component-heading-style">
                              Your Plastic Brain{" "}
                            </p>
                            <p>
                              Your brain is plastic – well not really, but
                              psychologists have discovered that{" "}
                              <span
                                className="normal-text-black"
                                style={{ color: "var(--purple)" }}
                              >
                                by changing your thoughts and actions, you
                                actually change the neural pathways in your
                                brain{" "}
                              </span>
                              just as though it was made of plastic. This is
                              often referred to as neuroplasticity. Each time
                              you do one of CBTpsych’s exercises, you’ll be
                              making new pathways in your brain. But be patient
                              -
                              <span
                                className="normal-text-black"
                                style={{ color: "var(--purple)" }}
                              >
                                {" "}
                                it takes time and persistence to replace old
                                habits or old pathways with new ones.{" "}
                              </span>
                            </p>
                            <p>
                              At first it will be easier to go with your old
                              habits, in this case, your negative thinking.
                              <span
                                className="normal-text-black"
                                style={{ color: "var(--purple)" }}
                              >
                                {" "}
                                Like exercise, the more you practice challenging
                                your unhelpful thoughts, the easier it will get!
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <FooterOfPrint />
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous col-6  px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Begin;
