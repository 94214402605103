import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part5/part5firstpage/part5firstpage.css";
import "../../../../styles/part5/part5completepage/part5completepage.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const Part5First = () => {
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  /***** params/constant */
  const title1 = "Should Statements";
  const title2 = "THE SHOULD SOLUTION";
  const title3 = "SHOULDS ARE PERSONAL";

  /***********Hooks **********/
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  /*******   Helper Functions  *******/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/tyranny_of_the_shoulds", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_5/essay`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Should Statements | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div>
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        s
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title1}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p>
                              Throughout this program we’ve touched on SHOULD
                              Statements and how they increase anger and impact
                              social confidence. Let’s go a bit further.
                            </p>
                            <p>
                              Lots of people make the mistake of believing that
                              things SHOULD be different. They get upset and
                              angry when things don’t go the way they think they
                              SHOULD, and they spend heaps of their mental life
                              thinking about how things SHOULD be.
                            </p>
                            <p>
                              Susan walked into a store and stuttered at the
                              counter. The shop-assistant appeared to be in a
                              grumpy mood. He snapped,
                            </p>

                            <div className="main-container my-5">
                              <div className=" sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/221.jpg`}
                                    alt="ben"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>
                              Susan was extremely upset after this and later
                              became quite angry. This anger lasted most of the
                              day. She kept thinking that “he SHOULDN’T have
                              been so rude”.
                            </p>
                            <p>
                              What’s the point of Susan’s thinking? She can’t
                              turn back time and she can’t change the
                              shop-assistant’s behavior. Anger based on SHOULDs,
                              doesn’t change past events. It’s true that Susan
                              could complain. But is this her best option? We’d
                              argue that her best option is that of accepting
                              the situation and moving on with her life.
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 1 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 1) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 1 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title2}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <div className="main-container my-5">
                              <div className=" sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/222.jpg`}
                                    alt="Ben"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>
                              A way for Susan to accept this situation and move
                              on is to put herself in the shop-assistant’s shoes
                              and try seeing the situation through her eyes.
                              Maybe the shop-assistant was rude because:
                            </p>
                            <p className="listing">
                              <ul className="listing">
                                <li>He was unwell and tired</li>
                                <li>His boss might have just told him off</li>
                                <li>
                                  Another customer had just been rude to him
                                </li>
                                <li>
                                  He just found out that a family member is
                                  terminally ill
                                </li>
                                <li>His pet just died</li>
                                <li>
                                  He got a speeding fine on the way to work
                                </li>
                              </ul>
                            </p>
                            <p>
                              If Susan could see the situation through the
                              shop-assistants eyes it would help her to accept
                              the situation and not take it personally. She
                              wouldn’t be wasting mental energy for the rest of
                              the day by going over the scene, and could instead
                              get on with her plans for the day.
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 2 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 2) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 2 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title3}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <div className="main-container mb-4">
                              <div className=" sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/223.jpg`}
                                    alt="Ben"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>
                              Susan’s SHOULD was simply a reflection of her life
                              experiences and her personal perspective. Her
                              SHOULD isn’t right just because she thinks it.
                              There are 8 billion people on the planet and they
                              have 8 billion different sets of experiences. Many
                              of them would have perceived Susan’s scene
                              differently from her. Some would say that:
                            </p>
                            <div className="main-container my-5">
                              <div className="part-5-container">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/224.png`}
                                    alt="shoulds"
                                  />
                                </div>
                              </div>
                              <div className="part-5-container">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/225.png`}
                                    alt="shoulds"
                                  />
                                </div>
                              </div>
                              <div className="part-5-container">
                                <div className="image-container">
                                  <img
                                    src={`${DOMAIN_NAME}images/part5/part5firstPage/226.png`}
                                    alt="shoulds"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>
                              Never forget that your SHOULDs are simply a
                              construction from the million moments of your life
                              experience – they’re not laws etched in stone.
                              They’re arbitrary and differ from
                              person-to-person. Given this, isn’t it sensible to
                              take SHOULD statements less seriously?
                            </p>
                            <p>
                              SHOULDs create enormous amounts of unhappiness for
                              people. SHOULDs involve rules which are often
                              impossible to live up to, because we are all
                              imperfect at times. Every single time a person
                              with a perfectionistic SHOULD goofs up, s/he is
                              not only unhappy because of the mistake itself,
                              but also because of the secondary problem of
                              guilt, shame and negatively reliving the past
                              situation again and again.
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <div>
                      <FooterOfPrint />
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      disabled={
                        audio?.audioData?.length > 0 ? buttonState : false
                      }
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5First;
