import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CasualBehaviours = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/more_safety_behaviours", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/lets_recap`, { replace: true });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Causal Thoughts and Safety Behaviours | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-0">
                    Causal Thoughts and Safety Behaviours
                  </div>
                  <div className="card-body">
                    <p className="">
                      Below are some examples of how negative causal thoughts:
                    </p>
                    <p className="listing">
                      <ul>
                        <li>
                          Cause a person to develop a negative image in their
                          mind of how they’re looking to others
                        </li>
                        <li>
                          Then cause them to do safety behaviours to try to get
                          rid of the anxiety caused by seeing themselves this
                          way.
                        </li>
                      </ul>
                    </p>
                    <div className="table-res-sty">
                      <table
                        border="0"
                        className="table more-safety-behaviours"
                      >
                        <tbody>
                          <tr className="headings">
                            {/* <td>&nbsp;</td> */}
                            <td>&nbsp;</td>
                            <td>Negative causal thoughts</td>
                            <td>The negative self-image in their mind</td>
                            <td>Safety behaviours</td>
                          </tr>
                          <tr>
                            {/* <td>Alana's example</td> */}
                            <td>
                              <img
                                alt="safety behaviour"
                                src={`${DOMAIN_NAME}images/part3/moreSafety/168.jpg`}
                                className="part3_more_safety_images"
                              />
                              {/* <img alt="Robyn" src={robynImage} className='part3_more_safety_images'/> */}
                            </td>
                            <td>
                              1. &nbsp;I won’t know what to say. They’ll think
                              I’m an idiot.
                            </td>
                            <td>
                              Pictures herself as an unintelligent ‘dumb
                              blonde.’
                            </td>
                            <td>
                              Avoids eye contact, says little, plays with her
                              phone.
                            </td>
                          </tr>
                          <tr>
                            {/* <td>John's example</td> */}
                            <td>
                              <img
                                alt="safety behaviour"
                                src={`${DOMAIN_NAME}images/part3/moreSafety/169.jpg`}
                                className="part3_more_safety_images"
                              />
                            </td>
                            <td>
                              2. I’ll shake uncontrollably and the class will
                              see.
                            </td>
                            <td>
                              Pictures an exaggerated image of herself shaking.
                            </td>
                            <td>
                              Grips book extremely tightly, hides behind chair,
                              arms folded.
                            </td>
                          </tr>
                          <tr>
                            {/* <td>Zoe's example</td> */}
                            <td>
                              <img
                                alt="safety behaviour"
                                src={`${DOMAIN_NAME}images/part3/moreSafety/170.jpg`}
                                className="part3_more_safety_images"
                              />
                            </td>
                            <td>
                              3. If I stutter I’ll get anxious and blush and
                              everyone will see.
                            </td>
                            <td>
                              Pictures herself as a red faced anxious looking
                              girl who stands out and doesn’t fit in.
                            </td>
                            <td>
                              Wears extra makeup, covers cheeks with hair,
                              avoids facing others directly.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                                        <FooterOfPrint />
                                    </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CasualBehaviours;
