import React, { useState } from "react";

import { DOMAIN_NAME } from "../../env/Baseurl";
import PasswordStrengthMeter from "../global/PasswordStrengthMeter";

const UserPasswordInfo = ({ handleChange, emptyCheck, values }) => {
  /*******   States  *******/
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassShown, setConfirmPassShown] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState("");

  /*******   HelperFunctions  *******/

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPassShown(confirmPassShown ? false : true);
  };

  /****************Passwrod validation ************ */

  const handlePasswordValidation = (e) => {
    setPassword(e.target.value);
    let regx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;
    let password = e.target.value;
    let response = regx.test(password);
    if (response) {
      setPasswordValidation("");
    } else {
      setPasswordValidation(
        `Password must contain at least 8 characters, at least one lowercase and uppercase letter, at least one symbol and one number.`
      );
      return false;
    }
  };

  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row m-0 align-items-center login-col-wrap justify-content-center">
        {/* <div className="col-md-6">
                    <div className="text-center">
                        <img 
                            height="auto" 
                            width="450px" 
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div> */}
        <div className="col-md-12">
          <div>
            <h3 className="text-center pagehead-texttitle">Set Password</h3>
            <form>
              <div className="card-body">
                <div className="form-group forminut-passeye">
                  <div className="labelSection">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="input-label-style"
                    >
                      Password
                    </label>
                    <div className="passStren input-label-style">
                      Password Strength :
                      <div>
                        <PasswordStrengthMeter password={password} />
                      </div>
                    </div>
                  </div>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control form-control-icon-space"
                    id="exampleInputEmail1"
                    defaultValue={values.password}
                    onChange={(e) => handlePasswordValidation(e)}
                    onBlur={handleChange("password")}
                  />
                  <div
                    className="passShow-eye"
                    onClick={togglePasswordVisiblity}
                  >
                    <i
                      className={
                        passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                  {values.password === "" && password === "" ? (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.password}
                      <br />
                    </small>
                  ) : (
                    ""
                  )}
                  {passwordValidation !== "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {passwordValidation}
                    </small>
                  )}
                </div>
                <div className="form-group forminut-confirmPassShow">
                  <div className="confirmpass-label-ins">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="input-label-style"
                    >
                      Confirm Password
                    </label>
                    {values.confirm_password === "" ||
                    values.password !== values.confirm_password ||
                    emptyCheck.confirm_password === "Password match." ? (
                      <>
                        <small
                          className={
                            emptyCheck.confirm_password === "Password match."
                              ? "text-success password-match-instr error-message-style"
                              : "text-danger password-match-instr error-message-style"
                          }
                        >
                          {emptyCheck.confirm_password}
                        </small>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    type={confirmPassShown ? "text" : "password"}
                    className="form-control form-control-icon-space"
                    id="exampleInputPassword1"
                    defaultValue={values.confirm_password}
                    onChange={handleChange("confirm_password")}
                    onBlur={(e) => setCPassword(e.target.value)}
                    disabled={
                      passwordValidation.trim().length > 0 ? true : false
                    }
                    // disabled={true}
                  />
                  <div
                    className="confirmPassShow-eye"
                    onClick={toggleConfirmPasswordVisiblity}
                  >
                    <i
                      className={
                        confirmPassShown ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPasswordInfo;
