import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part1/cheklistErrors/checklistErrors.css";

import React, { useEffect, useState } from "react";
import {
  selectOptions1,
  selectOptions10,
  selectOptions11,
  selectOptions2,
  selectOptions3,
  selectOptions4,
  selectOptions5,
  selectOptions6,
  selectOptions7,
  selectOptions8,
  selectOptions9,
} from "../../../../constants/part1/ChecklistCognitiveErrors";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetChecklistErrorsResponse } from "../../../../services/part1/get-checklist-errors-response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Select from "react-select";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { selectStyle } from "../../../../constants/global/StyleObject";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChecklistCognitiveErrors = () => {
  const [checklistErrorsResponse, responseErrorMessage] =
    GetChecklistErrorsResponse();
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   States *******/
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [options, setOptions] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
    eight: "",
    nine: "",
    ten: "",
    eleven: "",
  });

  /*******   Helper Functions  *******/
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const selectHandler = (name, value, ques, label) => {
    setOptions({ ...options, [name]: value });

    if (state.data.some((item) => item.question === ques)) {
      // Checking Question ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.question === ques) {
          return {
            ...item,
            question: ques,
            userResponce: label,
            quesindex: name,
          }; // Updating user selection in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { question: ques, userResponce: label, quesindex: name },
        ], // Adding new question response in state
      }));
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
      });
      const response = await axios.post(
        BASE_URL + `checklist_of_error_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Checklist of Error Api");
        ErrorLogging("HTTP POST /checklist_of_error_create/userId" + error);
      }
    }
    handleAudioStoreData();
    navigate("/part_1/thinking_exercise_3", { replace: true });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_1/thinking_exercise_3", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/causal_thoughts`, { replace: true });
  };
  /*******   Page Params  *******/

  const title = "Checklist of Thinking Errors";
  /*******   Hooks  *******/

  useEffect(() => {
    let isEmpty = Object.values(options).every((x) => x !== ""); // Checking if there is empty response
    setDisabled(!isEmpty);
  }, [options]);

  //   const pheight = [];
  //   const [parah, setParagrap] = useState(0);

  //   const myfunctions = () => {
  //     var mydata = document.getElementsByClassName("component-heading-style");
  //     for (var i = 0; i < mydata.length; i++) {
  //       pheight.push(mydata[i].offsetHeight);
  //     }
  //     const sortedarr = pheight.sort();
  //     setParagrap(sortedarr.pop());
  //   };
  //   useEffect(() => {
  //     console.log(parah, "hei");
  //   }, [parah]);

  //  setTimeout(myfunctions, 500);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Checklist of Cognitive Errors | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body mb-0 p-0">
                  <div className="title page-title-style mb-0">{title}</div>
                  <div className="card-body">
                    <div className="welcome-content">
                      <div>
                        <p>
                          Below are 10 common unhelpful thinking errors that
                          lead to both ‘causal’ thoughts and social anxiety.
                          We’ve all used them at some stage. See if you can
                          relate to any:
                        </p>

                        <div className="part1-main-container my-5 thinking-errors">
                          <div className="image-with-text">
                            <p className="component-heading-style text-center ">
                              Mind reading
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/65.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p className="">
                              Assumes you know what other people are thinking,
                              and that they see you in the same negative way
                              that you see yourself.
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center ">
                              Blame game
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/67.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              You blame yourself for something you weren’t
                              entirely responsible for, or you blame other
                              people and overlook ways that your own attitudes
                              and behaviour might have contributed to the
                              problem. For example, “It’s all my fault that our
                              footy team lost” or “Just because I was rude didn’t mean the teacher had to cry.”
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Fortune telling
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/68.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Predicting the future, usually while assuming the
                              worst. You just “know” that things will go
                              horribly wrong so you’re already anxious before
                              you’re even in the situation.
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Labelling
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/69.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Predicting the future, usually while assuming the
                              worst. You just “know” that things will go
                              horribly wrong so you’re already anxious before
                              you’re even in the situation.
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Catastrophizing
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/70.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              It blows things out of proportion and makes a
                              mountain out of a molehill. Catastrophizing
                              usually uses words such as it will be “awful,”
                              “terrible,” “disastrous” or “What if!!!!”
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              All-or-nothing thinking
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/74.png`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              See only extremes, you are right or wrong, good or
                              bad, with no in-betweens. Likewise, believing that
                              social anxiety can’t be improved because you’re
                              naturally shy is a common form of all-or-nothing
                              thinking that prevents confidence from being
                              developed.
                            </p>
                          </div>

                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Compliment rejector
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/66.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              You reject positive compliments from others and
                              insist that your accomplishments or positive
                              qualities don’t count, “I only did well in the
                              race because the other runners were hopeless.”
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Emotional reasoning
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/71.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Puts Emotions before Thoughts (bad news). This
                              causes people to make judgments on how they feel.
                              For example, “I feel like an idiot, so I really
                              must be one.” Or “I don’t feel like doing this, so
                              I’ll put it off.”
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              SHOULD statements
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/72.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              You get angry and criticize yourself or others for
                              not following your unwritten rules – “The shop
                              should have keep the last DVD for me” OR “I
                              shouldn’t have stuttered at the party”. Also look
                              out for “Musts” and “oughts” as similar offenders
                              like “Shoulds”.
                            </p>
                          </div>
                          <div className="image-with-text">
                            <p className="component-heading-style text-center">
                              Pessimistic glasses
                            </p>
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/congnitiveErrors/73.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              It’s like wearing glasses that only let you see
                              the negatives in life instead of the positives.
                              The glass is always half empty instead of seeing
                              it as half full. “Yes, I won a bike, but I’m not
                              happy about the colour”.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body mb-0 p-0">
                  <div className="title page-title-style mb-3">
                    Identifying Thinking
                  </div>
                  <div className="card-body">
                    <div className="part1-main-container checklist mb-5">
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/84.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/75.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/76.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/80.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/79.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/83.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/81.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/82.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/77.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className="image-with-text">
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/thinkingErrors/78.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="part1-main-container" style={{ justifyContent: 'flex-start' }}>
                      <div className='image-with-text'>
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/socialConfidence/29.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                      <div className='image-with-text'>
                        <div className="container">
                          <img
                            src={`${DOMAIN_NAME}images/part1/socialConfidence/29.jpg`}
                            alt="Stress Release tips"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div>
                      <p className="">
                        Below are different examples of self defeating thinking.
                        See if you can correctly identify the cognitive errors
                        using the drop boxes below:
                      </p>

                      {responseErrorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {responseErrorMessage}
                            </div>
                          </div>
                        </div>
                      ) : checklistErrorsResponse !== undefined &&
                        checklistErrorsResponse.length !== 0 ? (
                        <div className="part1-main-container my-5 thinking-errors self-defence">
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/59.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p className="">
                              While talking to Lucas, Ebony ‘knew’ he was
                              thinking she was uncool because her school dress
                              was slightly longer than all the other girls’
                              dresses.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[0]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions1}
                              onChange={(e) => {
                                selectHandler(
                                  "one",
                                  e.value,
                                  "While talking to Lucas, Ebony ‘knew’ he was thinking she was uncool because her school dress was slightly longer than all the other girls’ dresses.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.one.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/61.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Floyd told an offensive joke while on a first
                              date. For the rest of the evening he could ‘see’
                              himself being rejected in the days to come.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[1]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions2}
                              onChange={(e) => {
                                selectHandler(
                                  "two",
                                  e.value,
                                  "Floyd told an offensive joke while on a first date. For the rest of the evening he could ‘see’ himself being rejected in the days to come.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.two.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/63.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Even though Larissa’s birthday cake looked
                              amazing, she felt sad after spotting a small part
                              that wasn’t iced properly.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[2]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions3}
                              onChange={(e) => {
                                selectHandler(
                                  "three",
                                  e.value,
                                  "Even though Larissa’s birthday cake looked amazing, she felt sad after spotting a small part that wasn’t iced properly.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.three.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/60.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Beth believed that because she ate one chocolate
                              biscuit, she was a bad dieter.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[3]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions4}
                              onChange={(e) => {
                                selectHandler(
                                  "four",
                                  e.value,
                                  "Beth believed that because she ate one chocolate biscuit, she was a bad dieter.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.four.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/64.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Noah stopped himself asking the attractive shop
                              assistant where the band-aids were because he
                              thought, “What if I stutter! She’ll think I’m
                              sleazy or creepy and call security, or maybe she
                              has a big boyfriend who’ll bash me? I can’t
                              predict exactly what will happen, but if I stutter
                              I know it will be a disaster!”
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[4]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions5}
                              onChange={(e) => {
                                selectHandler(
                                  "five",
                                  e.value,
                                  "Noah stopped himself asking the attractive shop assistant where the band-aids were because he thought, “What if I stutter! She’ll think I’m sleazy or creepy and call security, or maybe she has a big boyfriend who’ll bash me? I can’t predict exactly what will happen, but if I stutter I know it will be a disaster!”",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.five.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/62.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>I’m a idiot because I stuttered in class.</p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[5]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions6}
                              onChange={(e) => {
                                selectHandler(
                                  "six",
                                  e.value,
                                  "I’m a idiot because I stuttered in class.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.six.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>

                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/65.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              I feel dumb for stuttering, so I really must be.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[6]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions7}
                              onChange={(e) => {
                                selectHandler(
                                  "seven",
                                  e.value,
                                  "I feel dumb for stuttering, so I really must be.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.seven.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/66.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              I should have been served first because I’ve been
                              waiting the longest.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[7]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions8}
                              onChange={(e) => {
                                selectHandler(
                                  "eight",
                                  e.value,
                                  "I should have been served first because I’ve been waiting the longest.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.eight.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/67.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              After being complimented about how great she
                              looked at the party, Jenni said, “You only think
                              that because Stephanie and Mary haven’t arrived
                              yet – they’re gorgeous.”
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[8]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions9}
                              onChange={(e) => {
                                selectHandler(
                                  "nine",
                                  e.value,
                                  "After being complimented about how great she looked at the party, Jenni said, “You only think that because Stephanie and Mary haven’t arrived yet – they’re gorgeous.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.nine.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/68.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Stephen blamed his mother for his failure in the
                              history test. “Although I didn’t bother to do much
                              work, she could’ve helped me more with my study”.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[9]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions10}
                              onChange={(e) => {
                                selectHandler(
                                  "ten",
                                  e.value,
                                  "Stephen blamed his mother for his failure in the history test. “Although I didn’t bother to do much work, she could’ve helped me more with my study”",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.ten.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="image-with-text">
                            <div className="container">
                              <img
                                src={`${DOMAIN_NAME}images/part1/selfDefeting/71.jpg`}
                                alt="Stress Release tips"
                              />
                            </div>
                            <p>
                              Orlando believed that if you stutter, you couldn’t
                              really hope to join the ‘popular’ group at school.
                              It was as simple as that. “You’re either cool or
                              you’re not”.
                            </p>
                            <Select
                              styles={selectStyle}
                              placeholder={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? "Pick which cognitive error this is"
                                  : checklistErrorsResponse.array[10]
                                      .userResponce
                              }
                              isDisabled={
                                checklistErrorsResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions11}
                              onChange={(e) => {
                                selectHandler(
                                  "eleven",
                                  e.value,
                                  "Orlando believed that if you stutter, you couldn’t really hope to join the ‘popular’ group at school. It was as simple as that. “You’re either cool or you’re not”.",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.eleven.slice(1),
                                }}
                              ></span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {checklistErrorsResponse !== undefined &&
                  checklistErrorsResponse.length !== 0 &&
                  checklistErrorsResponse.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? "Please select options from all dropdown to continue."
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 dashboard-footer">
          <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
        </div>
      </div>
      {/* </div > */}
    </>
  );
};

export default ChecklistCognitiveErrors;
